import React, { useRef, useState, useEffect } from "react";
import "../hero/style";
import { AudioVideoWrapper } from "./styles";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import VideoOverlayButtons from "./videoPlayerOverlay";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistSlider from "../audio-video-components/PlaylistSlider";
import { isTouchScreen } from "../../utils";
import CaptionText from "./customeCaption";
import MobilePlaylist from "../audio-video-components/mobilePlaylistSlider";
import LoaddingSpinner from "../UI-components/loadingSpinner";
import { TextWapper } from "./styles";
import { Box } from "@mui/material";
export default function customeVideoPlayer(props) {
  const videoPlayerRef = useRef(null);
  const videoOverlay = useRef(null);
  const [volume, setAdjustVolume] = useState(0);
  const [isPlay, setisPlay] = useState(props.conditionalPlaying); //(props.conditionalPlaying ? true : false
  const [currentSeek, setcurrentSeek] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [enableCaption, setEnableCaption] = useState(false);
  const videoWrapperRef = useRef(null);
  const deskplaylistref = useRef(null);
  const mobPlaylistRef = useRef(null);
  const [disableButton ,setDisableButton] = useState(false)
  const ccRef = useRef(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(props.index);
  const [orientation, setOrientation] = useState(
    props.deviceCurrent !== "mobile" ? "landscape" : "portrait"
  );

  const [overlayInteraction, setOverlayInteraction] = useState(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [subtitles, setsubtitles] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {}, [currentVideoIndex]);
  useEffect(() => {
    hidebuttons();
  }, [videoOverlay, ccRef]);

  useEffect(() => {}, [mobPlaylistRef, deskplaylistref, ccRef]);

  React.useEffect(() => {
    if (
      subtitles &&
      subtitles.length > 0 &&
      props.id !== "heroPlayer" &&
      props.id !== "campaignPlayer"
    ) {
      let roundSeektime = Math.round(currentSeek);

      let x = subtitles.filter((e) => {
        return Math.floor(e.startTime) == roundSeektime;
      });

      if (x !== undefined && x.length > 0) {
        let captionText = x && x[0].text;

        setText(captionText);
      }
    }
  }, [subtitles && currentSeek]);

  React.useEffect(() => {
    if (enableCaption) {
      getSubtileTextandInfo();
    }
  }, [currentVideoIndex]);

  React.useEffect(() => {
    getSubtileTextandInfo();
    return () => {
      props.isFullscreen && props.close();
    };
  }, []);

  useEffect(() => {
    if (
      videoWrapperRef &&
      props.isFullscreen &&
      props.id !== "makerVideos" &&
      window.innerWidth < 600
    ) {
      let elem = videoWrapperRef.current;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }, [videoWrapperRef]);

  const getCues = () => {
    const vid = document.createElement("video");

    const track = document.createElement("track");

    track.default = true;
    vid.append(track);
    return new Promise((res, rej) => {
      track.onload = (evt) => res([...vid.textTracks[0].cues]);
      track.onerror = (evt) => rej("invalid url");
      track.src = props.data[orientation][currentVideoIndex]["srt"];
    });
  };
  const getSubtileTextandInfo = async () => {
    const cues = await getCues();
    // for the demo we log only a few properties from the VTTCue object

    let data = cues.map(({ text, startTime, endTime }) => ({
      text,
      startTime,
      endTime,
    }));

    setsubtitles(data);
  };

  function hasAudio() {
    return (
      videoPlayerRef.mozHasAudio ||
      Boolean(videoPlayerRef.webkitAudioDecodedByteCount) ||
      Boolean(videoPlayerRef.audioTracks && videoPlayerRef.audioTracks.length)
    );
  }

  const setVolume = (volume) => {
    setAdjustVolume(volume);
  };

  const nextprevButtonAction = (index) => {
    setText("");
    setCurrentVideoIndex(index);
  };

  const onCaptionClick = () => {
    setEnableCaption((enableCaption) => !enableCaption);
    getSubtileTextandInfo();
  };

  const handleOnVideoProgress = (e) => {
    setTotalDuration(videoPlayerRef.current.getDuration());
    setcurrentSeek(e.playedSeconds);
  };
  const handleOnSeekChange = (e) => {
    setcurrentSeek(e.target.value);

    getSubtileTextandInfo();

    videoPlayerRef && videoPlayerRef.current.seekTo(e.target.value);
  };
  const handlePlay = () => {
    if (totalDuration === 0) {
      setTotalDuration(videoPlayerRef.current.getDuration());
    }
    setisPlay(true);
  };
  const handlePause = () => {
    setisPlay((isPlay) => !isPlay);
  };

  const onVideoEnd = () => {
    (props.id !== "makerVideos" ||
      currentVideoIndex === props.data[orientation].length - 1) &&
      setVideoEnded((videoEnded) => !videoEnded);
    if (
      props.data[orientation] &&
      props.data[orientation].length > 1 &&
      currentVideoIndex < props.data[orientation].length - 1
    ) {
      setCurrentVideoIndex((currentVideoIndex) => currentVideoIndex + 1);
    }
    (props.id !== "makerVideos" ||
      currentVideoIndex === props.data[orientation].length - 1) &&
      setisPlay((isPlay) => !isPlay);
    setText("");
  };

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches && videoPlayerRef !== undefined) {
      setOrientation("portrait");
    } else {
      if (videoPlayerRef !== undefined) {
      }
      setOrientation("landscape");
    }
  });

  const hidebuttons = () => {    
    clearTimeout(overlayInteraction);
    let overlayButton = videoOverlay.current;
    let mobpref = mobPlaylistRef.current;
    let deskplayref = deskplaylistref.current;
    let ccRefele = ccRef.current;
    overlayButton.style.display = "flex";
    if (mobpref) mobpref.style.opacity = "1";
    if (deskplayref) deskplayref.style.opacity = "1";
    overlayButton.style.pointerEvents = "auto";
    if (ccRefele)
      ccRefele.style.bottom =
        window.innerWidth > 550 && orientation === "landscape"
          ? "150px"
          : "185px";
          setDisableButton(false)
    let i = setTimeout(function () {
      let overlayButton = videoOverlay.current;
      overlayButton.style.display = "none";
      overlayButton.style.pointerEvents = "none";
      if (mobpref) mobpref.style.opacity = "0";
      if (deskplayref) deskplayref.style.opacity = "0";
      if (ccRefele) ccRefele.style.bottom = "15px";
      setDisableButton(true)
    }, 3000);
    setOverlayInteraction(i);
  };

  const showSpinnerfun = () => {
    setShowSpinner(true);
  };

  document.onkeypress = (e) => musiccontrols(e);
  function musiccontrols(e) {

    if (e.code === "Space") {
      hidebuttons()
  }
}

  return (
    <AudioVideoWrapper 
    // id={"videoWrapper"}
     ref={videoWrapperRef}>
      <Box
        className={
          props.isFullscreen ? "video-playlist-extra" : "video-playlist-ui "
        }
      >
        <Box
          className="video-playe-ui"
          onMouseMove={() => props.isFullscreen && hidebuttons()}
        >
          {showSpinner && <LoaddingSpinner open={showSpinner} />}
          {enableCaption && text !== "" && (
            <TextWapper ref={ccRef} id={"caption"}>
              <Box className="caption-text">
                <p>{text}</p>
              </Box>
            </TextWapper>
          )}
          <ReactPlayer
            url={props.data[orientation][currentVideoIndex].url}
            ref={videoPlayerRef}
            id={props.id}
            key={props.id}
            preload={props.id !== "campaignPlayer" ? "auto" : "none"}
            className="videoContainer"
            playing={props.isPaused && isPlay}
            volume={volume}
            muted={volume <= 0}
            loop={props.loops}
            height={"100%"}
            playsinline={true}
            width={"100%"}
            onBuffer={() => showSpinnerfun()}
            onEnded={onVideoEnd}
            onBufferEnd={() => setShowSpinner(false)}
            onProgress={(e) => handleOnVideoProgress(e)}
          ></ReactPlayer>

          <div className="overlay" ref={videoOverlay}>
            {props.isFullscreen && (
              <div className="close-vPlayer-parent">
                <Button
                 tabIndex={0}
                  variant="contained"
                  className="close-btn-vplayer"
                  onClick={props.close}
                >
                  <CloseIcon />
                </Button>
              </div>
            )}
            <div className="videop-actionbar">
              {props.isFullscreen && (
                <VideoOverlayButtons
                  data={props.data}
                  videoPlayerRef={videoPlayerRef}
                  currentSeek={currentSeek}
                  playing={isPlay}
                  setIndex={nextprevButtonAction}
                  index={currentVideoIndex}
                  cc={enableCaption}
                  volume={setVolume}
                  caption={onCaptionClick}
                  handlePause={handlePause}
                  handlePlay={handlePlay}
                  videoEnded={videoEnded}
                  orientation={orientation}
                  setVideoEnded={setVideoEnded}
                  deviceCurrent={props.deviceCurrent}
                  handleOnSeekChange={handleOnSeekChange}
                  totalDuration={totalDuration}
                  hasAudio={hasAudio()}
                  disableButton={disableButton}
                  currentVid={
                    props.data && props.data[orientation][currentVideoIndex]
                  }
                  // handleVolumeChange= {}
                />
              )}
            </div>
          </div>
        </Box>
        {props.isFullscreen &&
          props.data &&
          props.data[orientation].length > 1 &&
          window.innerWidth > 550 && (
            <div ref={deskplaylistref}>
              <PlaylistSlider
                setIndex={nextprevButtonAction}
                index={currentVideoIndex}
                orientation={orientation}
                deviceCurrent={props.deviceCurrent}
                data={props.data[orientation]}
              />
            </div>
          )}
        {props.isFullscreen &&
          props.data &&
          props.data[orientation].length > 1 &&
          window.innerWidth < 550 &&
          orientation == "portrait" && (
            <div ref={mobPlaylistRef}>
              <MobilePlaylist
                data={props.data[orientation]}
                setIndex={nextprevButtonAction}
                img={props.data.images}
                deviceCurrent={props.deviceCurrent}
                orientation={orientation}
                deviceCurrent={props.deviceCurrent}
                index={currentVideoIndex}
              />
            </div>
          )}
      </Box>
    </AudioVideoWrapper>
  );
}
