import React, { useState } from "react";
import { HeroBannerWrapper } from "./style";
import Button from "@mui/material/Button";
import VideoPlayer from "../audio-video-components/customeVideoPlayer";
import DialogSlider from "../UI-components/dialogSlider";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import dictonary from "../../../dictionary";
import { Box } from "@mui/material";

const HeroBannerComponent = () => {
  const [open, setopen] = useState(false);
  const [pause, setPause] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const clippathtransitioncreator = () => {
    let banner = document.querySelector(".videoContainer");
    let blkLogo = document.querySelector("#head-logo1");
    if (window.scrollY > 150) banner.classList.add("animate");
    if (window.scrollY < 150) banner.classList.remove("animate");
    if (window.scrollY > 150) blkLogo.classList.add("head-logo1");
  };

  window.addEventListener("scroll", clippathtransitioncreator);

  const onpenfullscreenplayer = () => {
    setopen(true);
  };
  const close = () => {
    document.querySelector("html").style.overflow = "unset";
    setopen(false);
  };
  const onPause = () => {
    setPause((pause) => !pause);
  };

  let device = () => {
    return window.innerWidth < 560 || window.innerWidth < window.innerHeight
      ? "mobile"
      : "desktop";
  };

  let data =
    device() == "mobile" && device
      ? dictonary.Pages.landing_page.components.hero.hero_video_mobile_version
      : dictonary.Pages.landing_page.components.hero.hero_video_desktop_version;

  let image_data = dictonary.Pages.landing_page.metadata;
  
  let watchNowData =
   device() == "mobile" && device
  ? dictonary.Pages.landing_page.components.hero.hero_WatchNow_video_mobile_version
  : dictonary.Pages.landing_page.components.hero.hero_WatchNow_video_desktop_version;


  return (
    <HeroBannerWrapper>
      <Box className="max-width">
        <section className="banner" id={"bannervideo"} href={"top"}>
          <div>
            <div>
              <img
                src={image_data.logo}
                id="head-logo1"
                className="head-logo"
                style={{
                  filter: " invert(0)",
                  zIndex: "10 ",
                }}
                alt={"Sony logo"}
              />
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                }}
              >
                <div
                  className="outerText font-Zuume-72 home-text"
                  key={"text1"}
                  style={{
                    color: "#000",
                    display: "flex",
                    height: device() == "mobile" ? "102%" : "107%",
                    width: "50%",
                    padding: "0 80px",
                    textAlign: "left",
                    position: "absolute",
                    lineHeight: "100px",
                    top: 0,
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: "120px",
                  }}
                >
                  <p style={{background:'unset'}}>
                    Discover <br />
                    your
                    <span
                      className="music-span"
                      style={{ paddingLeft: "104px" , background : "unset"}}
                    >
                      {" "}
                      music-
                    </span>{" "}
                    <br />
                    the way it was
                    <br />
                    <span
                      className="maent-span"
                      style={{ paddingLeft: "220px" , background : "unset"}}
                    >
                      Meant to
                    </span>{" "}
                    <br /> be heard
                  </p>
                  <div
                    className={"overlaybutton"}
                    style={{
                      color: "green",
                      display: "flex",
                      zIndex: 12,
                      fontSize: "86px",
                      background : "unset"
                    }}
                  >
                    <Button
                      onClick={() => onpenfullscreenplayer()}
                      variant="outlined"
                      className="font-poppins-regular"
                      aria-label={"Play Video"}
                      key={"watchnow"}
                      tabIndex={0}
                      style={{
                        marginLeft: "0px",
                        zIndex: 12,

                        borderRadius: "50px",
                        color: "#000",
                        border: "1px solid #000",
                        padding: "16px 27px",
                        background : "unset"
                      }}
                    >
                      <span className="font-poppins-regular"> Watch Video</span>

                      <OpenInFullOutlinedIcon
                        style={{ marginLeft: "10px", color: "000" ,background : "unset"}}
                      />
                    </Button>
                    <Button
                      aria-label={pause ? "Pause" : "Play"}
                      key={"pause"}
                      variant="text"
                      disableRipple
                      tabIndex={1}
                      className="font-poppins-regular"
                      style={{
                        color: "#000",
                        marginLeft: "16px",
                        marginRight: "16px",
                        background : "unset"
                      }}
                      onClick={() => onPause()}
                    >
                      <span className="font-poppins-regular" style={{ background : "unset"}}>
                        {" "}
                        {pause ? "Pause" : "Play"}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src={image_data.logo}
                className="head-logo 1"
                alt={"Sony logo"}
                style={
                  {
                    // filter:" invert(0)",
                    // zIndex: "12"
                  }
                }
              />
              <div className="videoContainer">
                <VideoPlayer
                  isPaused={pause || open}
                  loops={true}
                  index={0}
                  muted={true}
                  id={"heroPlayer"}
                  deviceCurrent={device()}
                  conditionalPlaying={pause}
                  data={data && data}
                />
                <div
                  key={"hometext"}
                  className="outerText font-Zuume-72 home-text"
                  style={{
                    color: "#fff",
                    display: "flex",
                    height: "100%",
                    width: "50%",
                    lineHeight: "100px",
                    padding: "0 80px",
                    textAlign: "left",
                    position: "absolute",
                    height: device() == "mobile" ? "100%" : "105%",
                    top: 0,
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: "120px",
                  }}
                >
                  <img
                    className="music-for"
                    src={image_data.app_logo_img}
                    alt={"application tag line for the music"}
                  />
                  <p style={{background:'unset'}}>
                    Discover <br />
                    your
                    <span
                      className="music-span"
                      style={{ paddingLeft: "104px", background : "unset" }}
                    >
                      {" "}
                      music-
                    </span>{" "}
                    <br />
                    the way it was
                    <br />
                    <span
                      className="maent-span"
                      style={{ paddingLeft: "220px", background : "unset" }}
                    >
                      Meant to
                    </span>{" "}
                    <br /> be heard
                  </p>
                  <div
                    className={"overlaybutton"}
                    style={{
                      color: "green",
                      zIndex: 12,

                      display: "flex",
                      opacity: "1 !important",
                      fontSize: "86px",
                      background : "unset"

                    }}
                  >
                    <Button
                      onClick={() => onpenfullscreenplayer()}
                      variant="outlined"
                      className="upbanner-btn font-poppins-regular "
                      aria-label={"Watch Video"}
                      tabIndex={2}
                      key={"watchnow2"}
                      style={{
                        borderRadius: "50px",
                        color: "#fff",
                        border: "1px solid #fff",
                        padding: "16px 27px",
                        background : "unset"

                      }}
                    >
                      <span className="font-poppins-regular"> Watch Video</span>

                      <OpenInFullOutlinedIcon
                        
                        style={{ marginLeft: "10px", color: "ffff",background : "unset" }}
                      />
                    </Button>
                    <Button
                      variant="text"
                      tabIndex={3}
                      aria-label={pause ? "Pause" : "Play"}
                      title={"pause"}
                      key={"pause2"}
                      disableRipple

                      className="pause-upbanner-btn "
                      style={{
                        color: "#fff",
                        marginLeft: "16px",
                        background : "unset",
                        marginRight: "16px",
                      }}
                      onClick={() => onPause()}
                    >
                      <span className="font-poppins-regular">
                        {" "}
                        {pause ? "Pause" : "Play"}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {open && (
          <DialogSlider
            open={open}
            style={{
              height: "100%",
              position: "fixed",
              padding: "0px 0px 0px 0px",
              backgroundColor: "",
              borderRadius: "",
              top: "inherit",
              bottom: "0px",
            }}
            className="slider-popup"
            onClose={close}
            contentComponent={
              <VideoPlayer
                isFullscreen={open}
                controls={true}
                loops={false}
                muted={true}
                index={0}
                isPaused={pause}
                deviceCurrent={device()}
                id={"heroPlayerfullscreen"}
                conditionalPlaying={true}
                close={() => close()}
                data={watchNowData && watchNowData}
              />
            }
          />
        )}
      </Box>
    </HeroBannerWrapper>
  );
};

export default HeroBannerComponent;
