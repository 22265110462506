import React, { useState } from "react";
import orange from "@mui/material/colors/orange";
import HeroBannerComponent from "./components/appComponent/hero/hero";
import { Box } from "@mui/material";
import HeroSlider from "./components/appComponent/heroSlider/heroSlider";
import Manifestdesk from "./components/appComponent/Manifest/desktopmanifestanimation";
import Manifesmob from "./components/appComponent/Manifest/mobileMenifestAnimation";

import { Suspense } from "react";

const ArtistLazy = React.lazy(() =>
  import("./components/appComponent/Artist/artistmain")
);
const BeniftLazy = React.lazy(() =>
  import("./components/appComponent/benifits/benifits")
);
const MakersLazy = React.lazy(() =>
  import("./components/appComponent/Maker/Makermain")
);
const ProductsLazy = React.lazy(() =>
  import("./components/appComponent/Products/productmain")
);
const CampaignLazy = React.lazy(() =>
  import("./components/appComponent/campaign/campaignVideo")
);
const FooterLazy = React.lazy(() =>
  import("./components/appComponent/footer/footer")
);

// import 'fonts.css'
export default function App() {
  const [showSkip, setshowSkip] = useState(false);
  const [currentScrollpos, setCurrentScrollpos1] = useState(0);
  const [isGredientPlaying, setisgredientPlaying] = useState(false);
  const [orientation, setOrientation] = useState("portrait");
  const [notifier, setNoftifier] = React.useState(false);
  const [openSliderFromApp, setOpensliderFromApp] = React.useState(false);
  const [isFullScreen, setIsfullScreen] = React.useState(false);
  const [skipbottononformfill, setSkipbottononformfillonkeypress] =
    React.useState("show");

  const skiptoSection = (e, htmlElement) => {
    let element = document.getElementById(htmlElement);
    if (element) {
      let headerOffset = htmlElement !== "products" ? 10 : 110;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  document.onkeydown = (e) => zx(e);
  function zx(e) {
    skipbottononformfill === "show" && setshowSkip(true);
  }

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      my: 4,
      mx: 10,
      p: 4,
    },
    lightBack: {
      // backgroundColor: orange["A300"]
    },
    title: {
      borderRadius: 1,
      m: 3,
      py: 6,
      px: 4,
      textAlign: "center",
    },
    titleText: {
      textShadow: "2px 2px black",
      fontSize: {
        mobile: 36,
        tablet: 52,
        desktop: 72,
      },
    },
  };

  const videoplyedopenslider = () => {
    setNoftifier(true);
  };
  const openJoinus = (e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "join_us",
      buttonText: e.target.textContent,
    });
    setSkipbottononformfill("hide");
    setOpensliderFromApp(true);
    setCurrentScrollpos(
      document.documentElement.scrollTop || document.body.scrollTop
    );
  };

  const isFullScreenfun = (e) => {
    setIsfullScreen(e);
  };

  const closeJoinus = () => {
    document.querySelector("html").style.overflow = "auto";
    setOpensliderFromApp(false);
    setshowSkip(false);
    setSkipbottononformfill("hide");
  };

  const campaignVideoplaying = (e) => {
    setisgredientPlaying(e);
  };

  const setCurrentScrollpos = (e) => {
    if (e > 100) {
      document.body.scrollTop = e;
      setCurrentScrollpos1(e);
    }
  };

  const setSkipbottononformfill = (e) => {
    setSkipbottononformfillonkeypress(e);
  };

  return (
    <>
      <Box sx={styles.container} style={{ margin: "0px", padding: "0px" }}>
        <Box
          sx={{
            ...styles.title,
            ...styles.lightBack,
          }}
          style={{ margin: "0px", padding: "0px" }}
        >
          <HeroBannerComponent isFullScreenfun={isFullScreenfun} />

          <HeroSlider
            open={openSliderFromApp}
            closeJoinus={closeJoinus}
            onvidstopslideropen={notifier}
            isGredientPlaying={isGredientPlaying}
            isFullScreen={isFullScreen}
            currentScrollpos={currentScrollpos}
            setCurrentScrollpos={setCurrentScrollpos}
            setSkipbottononformfill={setSkipbottononformfill}
          />

          {window.innerWidth > 550 && (
            <Manifestdesk skiptoSection={skiptoSection} />
          )}
          {window.innerWidth < 550 && (
            <Manifesmob skiptoSection={skiptoSection} />
          )}

          <Suspense fallback={<div>Loading...</div>}>
            {" "}
            <ArtistLazy
              skiptoSection={skiptoSection}
              showSkip={showSkip}
              isFullScreenfun={isFullScreenfun}
              currentScrollpos={currentScrollpos}
              setCurrentScrollpos={setCurrentScrollpos}
            />
          </Suspense>
          {/* <Suspense fallback={<div>Loading...</div>}>
            {" "}
            <BeniftLazy
              openJoinus={openJoinus}
              skiptoSection={skiptoSection}
              showSkip={showSkip}
              isFullScreenfun={isFullScreenfun}
              currentScrollpos={currentScrollpos}
              setCurrentScrollpos={setCurrentScrollpos}
              setSkipbottononformfill={setSkipbottononformfill}
            />
          </Suspense> */}
          <Suspense fallback={<div>Loading...</div>}>
            {" "}
            <MakersLazy
              id={"makermob"}
              skiptoSection={skiptoSection}
              showSkip={showSkip}
              isFullScreenfun={isFullScreenfun}
              currentScrollpos={currentScrollpos}
              setCurrentScrollpos={setCurrentScrollpos}
            />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            {" "}
            <ProductsLazy skiptoSection={skiptoSection} showSkip={showSkip} />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            {" "}
            <CampaignLazy
              notify={videoplyedopenslider}
              campaignVideoplaying={campaignVideoplaying}
            />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <FooterLazy />
          </Suspense>
          {/* <ParallexMainComponent/> */}
        </Box>
      </Box>
      <Box sx={{ ph: 2, backgroundColor: orange[50] }}></Box>
    </>
  );
}
