import { StrictMode } from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";
import theme from "./theme";
import "./components/styles.css";
import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";
const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      html: {
        backgroundColor: "background.default",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    }}
  />
);

const rootElement = document.getElementById("root");
ReactDOM.render( 
    <ParallaxProvider>
      {/* <ThemeProvider theme={theme}> */}
      {/* <CssBaseline /> */}
      {/* {inputGlobalStyles} */}
      <App />
      {/* </ThemeProvider> */}
    </ParallaxProvider>
,
  rootElement
);
