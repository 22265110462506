import React from "react";
import { Box, Button } from "@mui/material";
import { CustomeSwiperstylesWrapper } from "./styles";
import BulletNav from "../UI-components/bulletNav";
import { isTouchScreen } from '../../utils'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function customeBottomNav(props) {

  const isTouchScreenDevice = isTouchScreen()
  return (
    <CustomeSwiperstylesWrapper>
      <Box
        className="slider-bulltes"
        container
        fullwidth
        style={{
          position: "relative",
          padding:
            window.innerWidth > 550 &&
            props.from == "product" &&
            "0px 34px",
        }}
      >
        <Box fullwidth>
          <BulletNav data={props.data} index={props.index} color={"#000"} />
        </Box>
        <Box>
          <Button
            color={"primary"}
            variant="outlined"
            aria-label={"previous"}
            className={`${isTouchScreenDevice ? 'button-hovered' : 'button-not-hovered'}`}
            // onClick={() => prevclick()}
            onClick={() => props.prev()}
            style={{
              marginLeft: "10px",
              borderRadius: "20px",
              color: `${props.color}`,
              borderColor: `${props.color}`,
            }}
          >
            <ArrowBackIcon />
          </Button>
          <Button
            aria-label={"next"}
            variant="outlined"
            className={`${isTouchScreenDevice ? 'button-hovered' : 'button-not-hovered'}`}
            style={{
              marginLeft: "10px",
              borderRadius: "20px",
              color: `${props.color}`,
              borderColor: `${props.color}`,
            }}
            // onClick={() => nextclick()}
            onClick={() => props.next()}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
      </Box>
    </CustomeSwiperstylesWrapper>
  );
}
