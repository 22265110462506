import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ManifestWrapper } from "./style";
import { Parallax, useParallax } from "react-scroll-parallax";
import dictionary from "../../../dictionary";
import "animate.css";
export default function Manifest(props) {
  const [disabledParallex, setdisabledParallex] = useState(false);

  let device = "mobile_images";
  let imgData =
    dictionary && dictionary.Pages.landing_page.components.menifest[device];

  const animation1pic1 = useParallax({
    startScroll: 500,
    endScroll: 950,
    translateY: ["0px", "-50px"],
    disabled: disabledParallex,
  }).ref;
  const animation1pic2 = useParallax({
    startScroll: 500,
    endScroll: 950,
    translateY: ["30px", "0px"],
    translateX: ["-30px", "0px"],
    disabled: disabledParallex,
  }).ref;
  const animationText1 = useParallax({
    startScroll: 500,
    endScroll: 950,
    translateY: ["0px", "-30px"],

    disabled: disabledParallex,
  }).ref;
  const animationpic2 = useParallax({
    startScroll: 550,
    endScroll: 950,
    disabled: disabledParallex,
    translateY: ["-0px", "-60px", "easeIn"],
  }).ref;
  const animationtext2 = useParallax({
    startScroll: 550,
    endScroll: 950,

    disabled: disabledParallex,
    translateY: ["0px", "10px", "easeIn"],
  }).ref;
  const animationpic3 = useParallax({
    tartScroll: 600,
    endScroll: 950,

    disabled: disabledParallex,
    translateY: ["0px", "-4px", "easeIn"],
  }).ref;
  const animationtext3 = useParallax({
    tartScroll: 600,
    endScroll: 950,

    disabled: disabledParallex,
    translateY: ["0px", "-4px", "easeIn"],
  }).ref;

  return (
    <ManifestWrapper>
      <Grid container spacing={2} style={{ position: "relative" }}>
        <Grid item xs={12} md={4} sm={4} >
          <div
            className="mani-card"
            style={{ cursor: "pointer" }}
            onMouseDown={(e) => props.skiptoSection(e, "products")}
          >
            <div className="card-img mani-bg product-img1"  ref={ animation1pic1}>
              <img  loading={"lazy"} src={imgData[0].img}   ref={animation1pic2} alt={imgData[0].alt_text} />
            </div>
            <div className="manicard-content" ref={animationText1}>
              <div className="card-head-text">
                <p className="font-Zuume-72 for-text" tabIndex={0}>For The</p>
                <p className="font-atomic-large  ear-text">Ear</p>
              </div>
              <p className="font-poppins-regular for-subtext">
                {imgData[0].main_text}
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} sm={4} >
          <div
            className="mani-card product-second-mob"
            onMouseDown={(e) => props.skiptoSection(e, "benifit")}
          >
            <div className="card-img" ref={animationpic2}>
              <img
                className="web-img"
                src="./images/manifest/Manifest_For_The_Studio_Mob.png"
                src={imgData[1].img}
                loading={"lazy"}
                alt={imgData[1].alt_text}
              />
              {/* <img className="mob-img" src="./images/Manifest_For_The_Stage_Mob.png"  alt={"image"} /> */}
            </div>
            <div className="manicard-content " ref={animationtext2}>
              <div className="card-head-text">
                <p className="font-Zuume-72 for-text " tabIndex={0}>For The</p>
                <p
                  className="font-atomic-large"
                  style={{ textTransform: "lowercase" }}
                >
                  Stage
                </p>
              </div>
              <p className="font-poppins-regular for-subtext">
                {imgData[1].main_text}
              </p>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sm={4}
          
          style={{ zIndex: 0 }}
          onMouseDown={(e) => props.skiptoSection(e, "makers")}
        >
          <div className="mani-card">
            <div
              className="card-img  product-third-mob"
              style={{ cursor: "pointer" }}
              ref={animationpic3}
            >
              <img
                className="web-img"
                src={imgData[2].img}
                alt={imgData[2].alt_text}
              />
             
            </div>
            <div
              className="manicard-content mani-card-third"
              ref={animationtext3}
            >
              <div className="card-head-text">
                <p className="font-Zuume-72 for-text" tabIndex={0}>For The</p>
                <p className="font-atomic-large">Studio</p>
              </div>
              <p className="font-poppins-regular for-subtext">
                {imgData[2].main_text}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </ManifestWrapper>
  );  
}
