import styled from 'styled-components';

export const HeroSliderWrapper=styled.div`
  .hero-slider-content{
    padding:0px 80px 12px 80px;
    background:#000;
    color: #fff;
    display: flex;
    align-items:center;
    justify-content: space-between;  
    border-radius: 16px 16px 0px 0px;
  }

  .max-width{
    position: fixed;
    bottom: 0;
    max-Width:1920px;
    width: 100%;
    z-index:23;

  }
  .join-design-text{
    padding: 4px 5px;
    box-shadow: 0px 0px 5px #fff;
    border-radius: 4px;
    cursor:pointer
  }
  
  &&{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    border-radius:16px 16px 0px 0px;
    width: 100%;
    z-index: 9;
    background: #000;
  }
  .hero-slider-content{
    display:flex;
    flex-direction:column
  }
  .join-img{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
  }
  .mobile-swiper-design {
    display:none
  }
  .join-img img{
    width:120px
  }
  @media screen and (max-width: 1400px) {
    .hero-slider-content{
      padding:0px 80px 12px 80px;
    }
  }
  @media screen and (max-width: 767px) {
    .arrow-icon{
      display: block;
      position: absolute;
      background: #fff;
      height: 2px;
      width: 40px;
      top: 10px;
    }
    .arrow-icon  svg{
      display:none
    }

    .hero-slider-content{
      padding:0px 24px 0px 24px;
      height:80px;
      justify-content:center
    }
    .join-img img{
      width:120px
    }
    .mobile-swiper-design {
      height: 2px;
      display:flex;
      background: #fff;
      width: 40px;
      position: absolute;
      top: 26px;
      border-radius: 5px;
    }
  }
  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    .hero-slider-content {
      padding: 0px 16px 4px 16px;
    }
    .hero-slider-content{
      height:50px;
    }
    .arrow-icon{
      display:block
    }

  }
  .slider-popup .MuiDialog-container .MuiPaper-root{
    
  }
  @media screen and (max-device-width: 667px) and (orientation: landscape) {
    .hero-slider-content{
      height:75px;
    }
    .join-img { /* landscape join form trigger pop up by Gowtham, sabari */
      margin-top: 5px;
    }
    .arrow-icon{
      display:block
    }
  }
  `
export const SubmitWrapper=styled.div`

`
  
  export const SliderWrapperPopup=styled.div`
  &&{
    background:#000 !important;
    color:#fff;
    position:relative;
    padding:0px 80px 0px 80px; 
    margin:0 auto;
    max-width:1920px;
    border-radius:18px; 
    height:100% !important;
  }
  .you-are-in{
    padding:8px 80px 0px 80px;

  }
  .join-form {
    text-align: left !important;  /* join form text alignment by gowtham*/
  }

  .content-msg-div img{
    width:120px
  }
  .web-img{
    position:absolute;
    right:0px; 
    width:50%;
    bottom:0px;
    
  }
  .web-img_noWidth{
    position:absolute;
    right:0px; 
    bottom:0px;

  }
   .mobile-swiper-design {
    display:none
  }
  .Slide-Swiper-div{
    margin: 0 auto;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 35px;
    display:none;
  }
  .swipe{
    position: absolute;
    width: 48px;
    height:2px;
    border-radius:8px;
    background: #D9D9D9;
  }
  .content-msg-div{
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-wrap: wrap;

  }
  .slider-header{
     display: flex;
     flex-direction: column;
  } 
  .joinmusic-text{
    font-size: 16px;
    font-family: 'Poppins-regular';
    width: 302px !important;
    line-height: 20px;
  }
  .form_check input {
    height:24px;
    width:24px;
    margin:0px;
    border-radius:2px
  }
  .confirm-text{
    margin-left:8px; 
    font-size: 14px;
    line-height: 20px;
  }
  .confirm-text a{
    color:#fff;
    margin:0px 5px;
    text-decoration:underline;  
  } 
  .form_check{
    display: flex;
    align-items: flex-start;
    margin: 28px 0px;
  }
  .form_check
  .form_check p{
    margin-left:15px;
  }
  .join-text{
     display: flex; 
     justify-content: space-between;
  } 
  .join-content img{
    width:120px
  }
  .error-msg{
    color: #D73B3B; 
    display: flex;
    align-items: center;
    margin-top:8px;
    justify-content: flex-end;
  }
  .insider-form{
    display:flex;
    margin-top:40px
  }
  .Swipper-close{
    background: #fff;
   
  }
   
  .insider-form-left{
    margin-right:64px
  }
  .start-text,.is-not-right{ 
    width: 80%;
    padding-bottom:18px;
    margin: 0 auto;
  }
  .subscribe-btn{
    border: none;
    padding: 16px 24px;
    font-size: 16px;
    -webkit-text-fill-color: #000;
    color:#000;
  line-height: 24px;
  background: #fff;

    font-family: 'Poppins-regular';
    border-radius: 30px;
  }
  .subtext-width{
    width:29%;
  }
  .message-head{
    margin-top:8px;
  }
  .circle-div-join{
    display:none;
  }
  .circle-div-join-web{
    width: 863px;
    height: 863px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    position: absolute;
    right: -83px;
    bottom: -340px;
  }
  .circle-img-join-web {
    height: 100%;
    width: 100%;
    background-size: 100% 91%;
    background-repeat: no-repeat;
    background-position: inherit;
    position: absolute;
  }
  @media screen and  (max-width: 375px) {
   .insider-form-div .insider-form{
      // height: 337px !important;
      overflow:auto;
      }
      .circle-div-join{
        height:285px !important;
        width:285px !important;
      }
  }
  @media screen and (min-width:390px)and  (max-width: 449px) {
    .insider-form{
      height:427px !important;
      overflow:scroll;
      }
  }
  .jointoForm form.insider-form.insider-form-container-pop-up{
    height:auto !important;
  }

  @media screen and (min-width:450px)and (max-width: 560px) {
    .insider-form{
    height:390px !important;
    overflow:scroll;

    }
  }
  
  
  @media screen and (min-width: 744px) and (max-width:767px) {
    .circle-div-join-web{
      display:block !important;
      width: 550px !important;
      height: 550px !important; 
      bottom: -275px !important;
    }
  }

  @media screen and (max-width: 767px) {
    .is-not-right {
      width: 58% !important;
    }
    .fields-forms{
      flex-direction:column
    }

    .insider-form.insider-form-container-pop-up{
      overflow:hidden
    }
    .slider-content{
      padding:0px 16px 0px 16px
    }
    &&{
      PADDING:0PX !IMPORTANT;
      border-radius: 16px 16px 0px 0px;
    }
    .insider-form{
      flex-direction:column !important
    }
    .insider-form-left, .insider-form-right{
      width:100% !important
    }
    .subtext-width{
      width:100% !important
    }
    .circle-div-join{
      width: 350px;
      height: 350px;
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      display:block;
      position: absolute;
      right: -63px;
      bottom: -45px;
    }
    .circle-div-join-web{
      display:none;
    }
   .circle-img-join {
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: inherit;
    position: absolute;
    }
    &&{
      
    }
    .refresh-text{
      PADDING-LEFT: 48PX;
    cursor:pointer

    }
    .start-text {
      width: 57%;
    }
    .Slide-Swiper-div{
      display:flex
    }
    .subtext-width p{
      width: 76% !IMPORTANT;
      margin-top: 16px;
      font-size: 14px;
      PADDING-LEFT: 48PX;
      line-height: 16px;
    }
    .subtext-width p {
      width: 100%;
      margin-top: 16px;
      font-size: 14px;
      line-height: 16px;
    }
  }
  @media only screen and (width:744px) and (resolution: 163dpi) {
    .circle-div-join-web{
      display:block !important;
      width: 555px;
      height: 555px;
      bottom: -227px;
    }

  }
  
  @media only screen and (width:1024px) and (resolution: 163dpi) {
    .circle-div-join-web{
      display:block;
    }
  }
  @media screen and (max-width: 768px) {
    
    .confirm-text{
      font-size: 14px;
      line-height: 16px;
    }
     .you-are-in{
      padding:0
    }
    &&{
      padding:0px 36px 0px 36px
    }
    
    .slider-header { 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .subtext-width {
    width: 100%;
  }
  .mob-img{
    display:block;
    position:absolute;
    bottom:0px;
    right:0
  }
  .slider-content .insider-form-div .start-text{
    width:100%
  }
  .start-text .font-Zuume-56{
    margin-left: 88px;
  }
  .joinmusic-text {
    font-size: 14px;
    font-family: 'Poppins-regular';
    width: 251px;
    line-height: 20px;
    margin-left: 89px;
  }
  .join-text{
    margin-top: 36px;
    width:100%;
  }
    .submit-form-btn{
      display:flex;
      padding-bottom:28px;
      justify-content:center;
    }
    .mobile-swiper-design {
      height: 2px;
      display:flex;
      background: #fff;
      width: 40px;
      position: absolute;
      top: 24px;
      border-radius: 5px;
    }
    .font-Zuume-56{
      font-size:44px
    }
    .message-head{
      margin-top:24px;
      padding-left:16px;
    }
    .insider-form{
      flex-direction:column
    }
    .web-img,.web-img_noWidth{
      display:none
    }
    
    .content-msg-div img{
      width:130px;
      padding-left: 16px;
      margin-top: 70px;
    }
    .subtext-width p {
      width: 60%;
      margin-top: 16px;
      font-size: 14px;
      line-height: 16px;
    }
    .subtext-width{
      width:80%
    }
    .insider-form-left{
      margin-right:0px
    }
    .subscribe-btn-div{
      display:flex;
      justify-content:center;
    }
  }
  @media screen and (max-device-width: 700px) and (orientation: landscape) {
    .join-text {
      margin-top: 8px;
      width: 100%;
    }
  }
  @media screen and (min-width:561px) and (max-device-width: 767px) and (orientation: landscape) {
    .insider-form{
      height:130px !important
    }
    .fields-forms{
      flex-direction: row
    }
    .insider-form-pop-up{
      width:48% !important
    }
    
  }
  @media screen and (min-width:768px) and (max-width: 1024px)  {
     .insider-form.insider-form-container-pop-up{
      width:100%
    }
  }
  @media screen and (min-width:768px) and (max-device-width: 920px) and (orientation: landscape) {
    .insider-form{
      height: 80px !important;
    }
  }
  @media screen and (min-width:921px) and (max-device-width: 980px) and (orientation: landscape) {
    .insider-form{
      height:85px !Important
    }
  }
  @media screen and (min-width:981px) and (max-device-width: 1024px) and (orientation: landscape) {
    .circle-div-join-web {
      width: 700px !important;
      height: 700px !important;
      bottom: -303px !important;
    }
    .subtext-width {
      width: 50%;
  }
  }
  @media screen and (min-width:561px) and (max-device-width: 920px) and (orientation: landscape) {
    .insider-form{
      justify-content:space-between;
      flex-direction:row;
      flex-direction: row;
    }
  }
  @media screen and (min-width:561px) and (max-device-width: 767px) and (orientation: landscape) {
    .is-not-right{
      width: 68%  !IMPORTANT
    }
    
  }
  @media screen and (min-width:561px) and (max-device-width: 980px) and (orientation: landscape) {
    .join-text {
      margin-top: 0px;
      width: 100%;
  }
  .join-content img{
    width:90px
  }
  .insider-form-div .start-text{
    width: 84%;
  }
  .joinmusic-text{
    font-size:13px !important;
    width:78%;
  }
  .confirm-text {
    margin-left: 8px;
    font-size: 13px;
    line-height: 15px;
  }
  .form__field:placeholder-shown ~ .form__label,.subscribe-disable{
    font-size: 13px;
    line-height: 15px;
  }
  .insider-form{
    padding-left:6px;
    flex-direction:column 
  }
  
  .form__group{
    margin-top:8px
  }
  .slider-content .font-Zuume-56 {
    font-size: 28px;
    line-height: 26px!important;
  }
  .subscribe-disable{
    padding: 14px 14px;
  }

}

  @media screen and (max-device-width: 980px) and (orientation: landscape) {
    

    .slider-content .font-Zuume-56,.message-head,.is-not-right {
      font-size: 32px;
      line-height: 30px !important;
    }
     
    .Slide-Swiper-div{
      display:none
    }
    .form__field{
      font-size:16px
    }
    .subtext-width{
      width:50%
    }
    .content-msg-div img { 
    margin-top: 0px;
    width:90px
    }
    .subtext-width p {
      width: 55%! IMPORTANT;
      margin-top:0px
    }
    .insider-form-left,.insider-form-right{
      width:48%;
    }
    .slider-header .font-Zuume-56{
      font-size:44px;
    }
    
    .insider-form {
     
    }

    .circle-div-join-web{
      
      display:block;
      width: 355px!important;
      height: 355px!important;
      bottom: -66px!important;
    }
    .circle-img-join-web{
      background-size: 100% 100%;
    }
  }
  @media screen  and (max-width: 992px) {
    .insider-form{
     flex-direction: column; 
    }
    .insider-form-left{
      margin-right: 0;
    }
    .form__field{
      width:100%
    }
    .is-not-right{
      width: 80%;
    }
    .circle-div-join-web {
      width: 675px;
      height: 675px;
      // bottom:180px
    }
   }
   @media screen and (min-width: 820px) and (max-width: 992px){ 
    .insider-form{
      flex-direction:column ;
      height: auto  ;
      overflow: auto;
      margin-top:0px;
      padding-bottom: 20px;
    }
  }
 
   @media screen and  (max-width: 1024px) {

    .insider-form{
      flex-direction:row ;
      height: auto  ;
      overflow: auto;
      margin-top:0px;
      padding-bottom: 20px;
    }
    &&{
      padding:0px 36px 0px 36px
    }
    .insider-form-left{
      margin:0px;
    }
 }

@media screen  and (max-width: 1440px) {
  .insider-form-left,.insider-form-right{
    width:48%;
  }
  .insider-form{
    justify-content:space-between
  }
}
 @media screen and (min-width: 1025px) and (max-width: 1300px)
 {
  .insider-form{
    // flex-direction:column;
    // height: 220px;
    overflow: auto;
    margin-top:0px;
    padding-bottom: 20px;
   
  }
  .circle-div-join-web {
    width: 764px;
    height: 764px;
  }
}
.insider-form.insider-form-container-pop-up{
  overflow:hidden;
  padding-bottom:21px;
  }
  @media screen and (max-width: 1450px) {
    .start-text{
      width:78%
    }
  }
  @media screen and (max-width: 1300px) {
    .start-text{
      width:100%
    }
 
    &&{
      padding:0px 36px 0px 36px;
    }
    .insider-form-div .start-text{ 
      width: 80%;
    }
    
    .form__field{
      width:100%
    }
    .insider-form-left{
      margin-right:0px
    }
  }
  
  @media (min-height: 561px) and (max-height: 600px) 
  and (min-width: 1025px) and (max-width: 1280px) { 

    .insider-form{
     height:200px;
    }
}

  @media screen and (min-width: 1600px) {
    .confirm-text{
      margin-left:8px;
      font-size: 14px;
      line-height: 20px;
      width: 60%;
    }
  }
  @media screen and (min-width:320px){
    .insider-form.insider-form-container-pop-up{
      min-width: calc(100% - 40px);
      max-width: 100%;
      // width: calc(100% - 40px);
      flex-wrap: wrap;
      height: auto !important;
    }
  }
    @media screen and (min-width:320px)  and (max-width: 539px){
    
      .insider-form-left.insider-form-pop-up{
        width: 100% !important;
        margin-right: 0;
      }
      }
      @media screen and (min-width:481px) and (max-width: 539px){
        .insider-form-left.insider-form-pop-up{
        width: 100%;
        margin-right: 0;
      }
      }
      
      @media screen and (min-width:641px)  {
        #swipeme-text + p{
          width:85%
        }
        .insider-form-pop-up{
        width: calc(100%/2 - 32px);
        }
      } 
      @media screen and (min-width:961px)  {
        #swipeme-text + p{
          width:85%
        }
        .insider-form-pop-up{
        width: calc(100%/2 - 32px);
        }
      }
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
        .insider-form-pop-up{
          width:48%;
      }
      .circle-div-join-web {
        width: 700px !important;
        height: 700px !important;
        bottom: -303px !important;
      }
      .insider-form.insider-form-container-pop-up{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        min-width: 100%;
        width: 100%;
        flex-direction: column;
      }
      }
      @media only screen and (width:1024px) and (resolution: 163dpi) {
 .insider-form-pop-up{
            width:48%;
        }
        .insider-form.insider-form-container-pop-up{
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          min-width: 100%;
          width: 100%;
          flex-direction: column;
        }
      }
      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        .insider-form-pop-up{
            width:48%;
        }
        .insider-form.insider-form-container-pop-up{
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          min-width: 100%;
          width: 100%;
          flex-direction: column;
        }
      }
  @media screen and (max-device-width: 1024px) and (orientation: landscape) {
    .insider-form-pop-up{
      width:48%;
  }
  .insider-form.insider-form-container-pop-up{
    width:100%
  }
  .insider-form.insider-form-container-pop-up{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
    width: 100%;
    flex-direction: column;
    }
  }


`


export const JoinNowPopupWrapper=styled.div `

`