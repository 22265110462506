import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import red from "@mui/material/colors/red";

import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";

const AppTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            primary: {
              main: red[500],
              contrastText: grey[50]
            }
          }
        : {
            primary: {
              main: red[800],
              contrastText: grey[50]
            }
          }),
      secondary: {
        main: "#000000",
        contrastText: 'white'
      },
      action: {
        active: red[200],
        activeOpacity: 1,
        disabled: grey[700],
        disabledBackground: grey[200],
        hover: red[100],
        hoverOpacity: 0.7,
        focus: red[600],
        focusOpacity: 1,
        selected: red[300],
        selectedOpacity: 1
      },
      background: {
        default:'#000000',
        paper: grey[200]
      },
      common: {
        black: grey[900],
        white: grey[200]
      },
      error: {
        light: red[400],
        main: red[500],
        dark: red[300],
        contrastText: grey[800]
      },
      success: {
        light: green[400],
        main: green[500],
        dark: green[300],
        contrastText: grey[800]
      },
      text: {
        primary: grey[900],
        secondary: grey[700],
        disabled: grey[500]
      },
      grey: grey,
      tonalOffset: 0.2
    },
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200
      }
    },
    components: {
      MuiAlert: {
        defaultProps: {
          style: {
            marginBottom: 6
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              margin: 10,
              backgroundColor: red[300]
            }
          },
          {
            props: { variant: "outlined" },
            style: {
              margin: 10,
              color: "#000000"
            }
          }
        ]
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: "test" },
            style: {
              color: grey[200],
              fontSize: 80,
              fontWeight: 800
            }
          },
          {
            props: { variant: "body1" },
            style: {
              fontSize: 24
            }
          }
        ],
        defaultProps: {
          style: {
            textAlign: "right"
          }
        },
        variantMapping: {
          h1: "h2",
          h2: "h2",
          body1: "span"
        }
      }
    },
    shape: {
      borderRadius: 20
    },
    spacing: 4,
    typography: {
      fontFamily: ["Roboto"],
      fontSize: 16,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      lineHeight: 1.5,
      h1: {
        color: grey[100],
        fontSize: "6rem",
        fontWeight: 400
      },
      h2: {
        color: grey[50],
        fontSize: "3rem",
        fontWeight: 700
      },
      body1: {
        color: grey[800],
        fontSize: "2rem",
        fontWeight: 400
      }
    },
    zIndex: {
      modal: 1300,
      snackbar: 1400,
      drawer: 1200,
      appBar: 1100,
      mobileStepper: 1000,
      tooltip: 1500
    }
  });

const theme = responsiveFontSizes(AppTheme("light"));

export default theme;
