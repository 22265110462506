import styled from 'styled-components';

export const ManifestWrapper=styled.div`
 &&{
   padding:120px 80px;
   position:relative;
 }
 .card-img{
  width: 210px;
  margin: 0 auto;
  height: 210px;
  border-radius: 50px;
 }
.mani-card{
  // padding:36px;
  position:relative;
}
 .card-img img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
 }
 .mani-bg{
  background-image:url(./images/Polygon.png)
 }
 
 .mani-bg{
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
  position:relative;
 }
 .for-text{
  margin-left: 60px;
  text-align: left;
 }
 .manicard-content{
   padding:24px 80px
 }
.ear-text{
  padding-right: 110px;
  text-transform:uppercase !important; 
}
  .for-subtext{
    margin-top:30px;
    font-size: 16px;
    margin-left:60px;
    line-height: 20px;
    text-align:left;
    width:70%
  }
 .mani-bg img{
  rotate:-36.41deg;
  position: absolute;
  bottom:-15px;
    left: -33px;
}
.mob-img{
  display:none
}
.animate__animated.animate__slideInUp {
  --animate-duration: .8s;
}

@keyframes drop-in {
  from {
    transform:
      rotate(0deg) translateX(4%) translateY(4%);
    opacity: 1;
    
  }
  to {
    transform:
      rotate(-8deg) translateX(-120%) translateY(10%);
    opacity: 1;
  }
}
@media screen and (max-width: 567px) {
  &&{
    padding:124px 16px 48px 16px!important
  }
  
 
  .product-third-mob .mob-img{
    display:block
  }
  .product-third-mob{
    right: 0;
    border-radius: 0;
  }
   .ear-text {
    padding-right: 20px !important;
  }
  .product-third-mob img{
    width: auto;
    height: auto;
    border-radius: 0;
  }
   
  .card-img{
    width:200px !important;
    height:200px !important;
  }
  .font-Zuume-72{
    font-size: 72px; !important;
    margin-left:0px
  }
  .font-atomic-large{
    text-align: left;
    margin-left: 69px;
  }
  .product-img1{
    position: absolute;
    right: -3px;
    top: -60px;
  }
  .manicard-content{
    margin-bottom:36px;
    padding-right:16px !important;
  }
  
  .manicard-content .for-subtext{
    width: 260px !important;
    margin-left: 65px;
  }
  .product-second-mob .card-img{
    position: absolute;
    left: -48px;
    top: -55px;
  }
  .product-second-mob .card-head-text .for-text {
    text-align: right;
    margin-right: 16px;
  }
  .product-second-mob .card-head-text .font-atomic-large{
    text-align: right;
    margin-right: 84px;
  }
  .product-second-mob .for-subtext{
    padding-left: 124px;
    margin-left:0px;
    width: 264px !important;
  }
  .product-third-mob{
    position: absolute;
    right: -80px;
    top: 44px;
  }
  .mani-card-third .for-subtext {
    width: 195px!important;
    margin-left: 65px;
  }
}

@media screen and (max-width: 567px) {
  &&{
    padding:124px 0px 80px 16px!important
  }
  .for-subtext{
    margin-top:12px;
  }
  .mani-card .product-img1{
    height:170px !important;
    width:170px !important;

  }
  .product-third-mob .font-atomic-large{
    margin-left: 45px;
  }
 .mani-card-third .for-subtext {
  width: 187px!important;
  margin-left: 43px;
  }
  .product-second-mob .for-text .font-atomic-large {
    margin-left: 135px
  }
  .font-atomic-large{
    font-size:65px;
    line-height:60px
  }
  
  .product-second-mob{
    margin-top:48px;
  }
  .product-third-mob .mob-img{
    display:block
  }
  .product-third-mob{
    right: 0;
    border-radius: 50%;
  }
  .product-third-mob img{
    width: 200px!important;
    height: 200px!important;
    border-radius: 50%;
  }
  .card-img{
    width:200px !important;
    height:200px !important;
  }
  .font-Zuume-72{
    font-size: 72px !important;
    margin-left:0px
  }
  .font-atomic-large{
    text-align: left;
    margin-left: 69px;
  }
  .product-img1{
    position: absolute;
    right: -3px;
    top: -60px;
  }
  .manicard-content{
    margin-bottom:36px
  }
  .manicard-content .for-subtext{
    width: 230px !important;
    margin-left: 65px;
    font-size:14px;
  }
  .product-second-mob .card-img{
    position: absolute;
    left: -48px;
    top: -84px;
  }
  .product-second-mob .card-head-text .for-text {
    text-align: right;
    margin-right: 16px;
  } 
  .product-second-mob .card-head-text .font-atomic-large{
    text-align: right;
    margin-right: 74px;
  }
  .product-second-mob .for-subtext{
    padding-left: 135px;
    margin-left: 0px;
    width: 222px!important;
  }
  .mani-card .product-third-mob{
    position: absolute;
    right: -80px;
    width:auto !important ;
    top: 10px;
  }
  .mani-card-third .for-subtext {
    width: 195px!important;
    margin-left: 65px;
  }
}  

@media screen and (min-width: 992px) and (max-width: 1280px) {
  .for-subtext,.for-text{
    margin-left:24px;
  }
  .mani-bg img{
    bottom: 5px;
  }
}
@media screen and (max-width: 992px) {
  .card-img {
    width: 175px;
    height: 175px; 
  }
  .for-subtext,.for-text{
    margin-left:0px;
  }
  .ear-text{
    padding-right:31px

  }
 .mani-bg img{
  bottom: 17px;
  left: -33px;
  }
  .font-poppins-regular{
    font-size:14px;
    line-height:16px;
  }
   .for-subtext{
    width:100% !important
  }
}



@media screen and (min-width: 1441px) {
  .for-text{
    font-size: 91px;
      line-height: 82px;
  }
  .font-atomic-large {
    font-size: 75px;
    line-height: 70px;
  }
}
@media screen and (max-width: 1250px) {
  &&{
    padding:120px 36px
  }
  .mani-card{
    padding:0px
  }
}

@media screen and (max-width: 400px) {
  .product-img1{
    right:-12px
  }
  .mani-card-third .for-subtext {
    width: 186px!important;
    margin-left: 36px;
  }
  .mani-card-third .card-head-text .font-atomic-large{
    margin-left: 36px;
  }
}
@media screen and (max-width: 360px) {
  .font-atomic-large {
    font-size: 56px;
    line-height: 56px;
  }
  .mani-card-third .card-head-text .font-atomic-large{
    margin-left:18px
  }
  .product-second-mob .for-subtext {
    padding-left: 116px; 
  }
  .product-second-mob .card-head-text .font-atomic-large {
    margin-right: 85px;
  }
  .mani-card-third .for-subtext {
    width: 185px!important;
    margin-left: 22px;
  }
 }

@media screen and (max-width: 1400px) {
  .for-subtext{
    width:80%
    
  }
}
@media (min-width: 1701px) and (max-width: 1900px) {
.manicard-content{
   padding:24px 40px
  }
}
@media screen and (max-width: 1700px) {
  .manicard-content{
    padding:24px 0;
  }
}
@media screen and (max-device-width: 900px) and (orientation: landscape) {
  &&{
    padding:80px 24px
  }
  .ear-text {
    padding-right: 50px;
  }
  .font-Zuume-72{
    font-size:60px
  }  
}
`