import React from "react";

import PauseIcon from "@mui/icons-material/Pause";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import SliderDialog from "../UI-components/dialogSlider";
import {
  AudioSwiperWrapper,
  MobilePlaylistWrappper,
  PlayListWrapper,
} from "./styles";
import dictonary from "../../../dictionary";
import { Box, Grid, IconButton } from "@mui/material";
import { isTouchScreen } from "../../utils";

export function MobilePlaylistSlider(props) {
  const dataimages = dictonary.Pages.landing_page.components.makers.images;
  const [play, setPlay] = React.useState(true);
  const [currentIndex, setCurrentIndex] = React.useState(true);

  const [element, setElement] = React.useState(null);
  function detectswipe(el, func) {
    if (isTouchScreen) {
      let swipe_det = new Object();
      swipe_det.sX = 0;
      swipe_det.sY = 0;
      swipe_det.eX = 0;
      swipe_det.eY = 0;
      var min_x = 20; //min x swipe for horizontal swipe
      var max_x = 40; //max x difference for vertical swipe
      var min_y = 40; //min y swipe for vertical swipe
      var max_y = 50; //max y difference for horizontal swipe
      var direc = "";
      let ele = el;
      ele.addEventListener(
        "touchstart",
        function (e) {
          var t = e.touches[0];
          swipe_det.sX = t.screenX;
          swipe_det.sY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();
          var t = e.touches[0];
          swipe_det.eX = t.screenX;
          swipe_det.eY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchend",
        function (e) {
          //horizontal detection
          if (
            (swipe_det.eX - min_x > swipe_det.sX ||
              swipe_det.eX + min_x < swipe_det.sX) &&
            swipe_det.eY < swipe_det.sY + max_y &&
            swipe_det.sY > swipe_det.eY - max_y
          ) {
            if (swipe_det.eX > swipe_det.sX) direc = "r";
            else direc = "l";
          }
          //vertical detection
          if (
            (swipe_det.eY - min_y > swipe_det.sY ||
              swipe_det.eY + min_y < swipe_det.sY) &&
            swipe_det.eX < swipe_det.sX + max_x &&
            swipe_det.sX > swipe_det.eX - max_x
          ) {
            if (swipe_det.eY > swipe_det.sY) direc = "d";
            else direc = "u";
          }

          if (direc != "") {
            if (typeof func == "function") func(el, direc);
          }
          direc = "";
        },
        false
      );
    }
  }

  React.useEffect(() => {
    let ele = document.querySelector("#mobswipemeinside2");
    if (ele) {
      setElement(ele);
    }
  }, [element]);

  function myfunction(el, d) {
    if (d == "d") {
      props.closenew();
    }
  }

  element != null && detectswipe(element, myfunction);
  const sliderContent = props.data.map((e, i) => (
    <MobilePlaylistWrappper>
      <Box
        item
        tabIndex={i}
        xs={12}
        id={"mobswipemeinside2"}
        key={`mobplaylist${i}`}
        onClick={() => props.setIndex(i)}
        className="video-playlist_mobile"
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: props.index == i ? "#000" : "transparent",
          color: props.index === i ? "#fff" : "#000",
        }}
      >
        <div>
          <div className="mob-play-list">
            <img
              alt={dataimages[i].alt_text}
              className="m-auto_mob"
              loading={"lazy"}
              height={"80px"}
              width={"80px"}
              src={dataimages[i].playlist_mob}
            />

            <div>
              <p>{dataimages[i].makersName}</p>
              <p>{dataimages[i].discription}</p>
            </div>
          </div>
        </div>
        <div>
          {props.index === i ? (
            <IconButton>
              <PauseIcon style={{ color: "#fff" }} />
            </IconButton>
          ) : (
            dataimages[i].duration
          )}
        </div>
      </Box>
    </MobilePlaylistWrappper>
  ));

  return (
    <AudioSwiperWrapper>
      <Box className="playlist-text-popup" id={"mobswipemeinside2"}>
        <Box className="mobile-playlist">
          <Box className="playlist-swiper-auto">
            <p className="font-poppins-22">Playlist</p>
            <p className="length">({props.data.length})</p>
          </Box>
          <Box
            className="mobile-swiper-design"
            onClick={() => props.closenew()}
          ></Box>
        </Box>
        <Box className="mobile-playlist-scroll">{sliderContent}</Box>
      </Box>
    </AudioSwiperWrapper>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HeroSlider(props) {
  const [open, setOpen] = React.useState(false);
  const [element, setElement] = React.useState(null);
  React.useEffect(() => {
    let ele = document.querySelector("#mobswipeme");
    if (ele) {
      setElement(ele);
    }
  }, [element]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function detectswipe(el, func) {
    let swipe_det = new Object();
    swipe_det.sX = 0;
    swipe_det.sY = 0;
    swipe_det.eX = 0;
    swipe_det.eY = 0;
    var min_x = 20; //min x swipe for horizontal swipe
    var max_x = 40; //max x difference for vertical swipe
    var min_y = 40; //min y swipe for vertical swipe
    var max_y = 50; //max y difference for horizontal swipe
    var direc = "";
    let ele = el;
    ele.addEventListener(
      "touchstart",
      function (e) {
        var t = e.touches[0];
        swipe_det.sX = t.screenX;
        swipe_det.sY = t.screenY;
      },
      false
    );
    ele.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
        var t = e.touches[0];
        swipe_det.eX = t.screenX;
        swipe_det.eY = t.screenY;
      },
      false
    );
    ele.addEventListener(
      "touchend",
      function (e) {
        //horizontal detection
        if (
          (swipe_det.eX - min_x > swipe_det.sX ||
            swipe_det.eX + min_x < swipe_det.sX) &&
          swipe_det.eY < swipe_det.sY + max_y &&
          swipe_det.sY > swipe_det.eY - max_y
        ) {
          if (swipe_det.eX > swipe_det.sX) direc = "r";
          else direc = "l";
        }
        //vertical detection
        if (
          (swipe_det.eY - min_y > swipe_det.sY ||
            swipe_det.eY + min_y < swipe_det.sY) &&
          swipe_det.eX < swipe_det.sX + max_x &&
          swipe_det.sX > swipe_det.eX - max_x
        ) {
          if (swipe_det.eY > swipe_det.sY) direc = "d";
          else direc = "u";
        }

        if (direc != "") {
          if (typeof func == "function") func(el, direc);
        }
        direc = "";
      },
      false
    );
  }

  function myfunction(el, d) {
    if (d == "u") {
      setOpen(true);
    }
  }

  element != null && detectswipe(element, myfunction);

  return (
    <PlayListWrapper>
      <Box variant="outlined" onClick={handleClickOpen} id={"mobswipeme"}>
        <Box className="hero-slider-content">
          <Box className="playlist-popup">
            <Box className="playlist-swiper-auto">
              <p className="font-poppins-22">Playlist</p>
              <p className="playlist-lenght">({props.data.length})</p>
            </Box>
            <Box className="mobile-swiper-design"></Box>
          </Box>
        </Box>
      </Box>
      <SliderDialog
        open={open}
        close={""}
        className="Slider-playlist"
        sliderHeight={"inherit"}
        style={{
          height: "65vh",
        }}
        contentComponent={
          <MobilePlaylistSlider
            numberofSlides={props.data.length}
            {...props}
            closenew={handleClose}
          />
        }
      />
    </PlayListWrapper>
  );
}
