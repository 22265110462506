const dictonary = {
  Pages: {
    landing_page: {
      metadata: {
        page_title: ["for the music", "leave the music"],
        logo: "newAssets/images/sony.png",
        logo_image_alt_tag: "sony logo",
        app_image_alt_tag: "for the music logo",
        app_logo_img: "newAssets/images/forthemusic.png",
      },
      components: {
        hero: {
          hero_video_desktop_version: {
            landscape: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb_v2_edited.mp4",
                // url: "images/hero.mp4",

                discription: " ULT Power Sound Series x Peso Pluma",
                makersName: "For The Music",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
            portrait: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb_v2_edited.mp4",

                // url: "images/hero.mp4",

                makersName: "For The Music ",
                discription: "ULT Power Sound Series x Peso Pluma",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
          },

          hero_video_mobile_version: {
            landscape: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb_v2_edited.mp4",
                discription: "ULT Power Sound Series x Peso Pluma",
                makersName: "For The Music ",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
            portrait: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_9x16_With-Tagline_No-BlackEndCard_15Mb_v2_EDITED.mp4",
                discription: " ULT Power Sound Series x Peso Pluma",
                makersName: "For The Music ",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
          },
          hero_WatchNow_video_desktop_version: {
            landscape: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb_v2.mp4",
                // url: "images/hero.mp4",

                discription: " ULT Power Sound Series x Peso Pluma",
                makersName: "For The Music ",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
            portrait: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb_v2.mp4",

                // url: "images/hero.mp4",

                makersName: "For The Music ",
                discription: " ULT Power Sound Series x Peso Pluma",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
          },

          hero_WatchNow_video_mobile_version: {
            landscape: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb_v2.mp4",
                discription: "ULT Power Sound Series x Peso Pluma",
                makersName: "For The Music ",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
            portrait: [
              {
                url: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_9x16_With-Tagline_No-BlackEndCard_15Mb.mp4",
                discription: " ULT Power Sound Series x Peso Pluma",
                makersName: "For The Music",
                srt: "newAssets/videos/Global-Ad_ULT-TOWER-10_60ss_16X9_With-Tagline_No-BlackEndCard_20Mb.mp4.vtt",
              },
            ],
          },
          hero_text: "Discover the music the way it was meant to be heard",
          button_names: ["Watch Video", "pause"],
        },
        makers: {
          makers_video_desktop_version: {
            landscape: [
              {
                url: "newAssets/videos/SONYxMIGUEL_E5_Interview_Edited_Text_16x9_v2-SD_960x540p_20MB.mp4",
                discription: "Sony Music Artist Miguel Interview",
                srt: "newAssets/videos/SONYxMIGUEL_E5_Interview_CCs_16x9_1.mp4.vtt",
                artistName: "Miguel shares his thoughts on the WF-1000XM5",
              },
              {
                url: "newAssets/videos/SONY22_SEL_IANEASTWOOD_4K_16x9_WEB-SD_960x540p.mp4",
                discription: "Choreographer and Audio Ambassador",
                makersName: "Ian Eastwood",
                srt: "newAssets/videos/IANEASTWOOD_CC'S_16x9.mp4.vtt",
              },
              {
                url: "newAssets/videos/SONY22_SEL_AUSTINMILLZ_4K_16x9_WEB-SD_960x540p.mp4",
                discription: "DJ and Audio Ambassador",
                makersName: "Austin Millz",
                srt: "newAssets/videos/AUSTINMILLZ_CC'S_16x9.mp4.vtt",
              },


            ],
            portrait: [
              {
                url: "newAssets/videos/SONYxMIGUEL_E5_Interview_Edited_Text_16x9_v2-SD_960x540p_20MB.mp4",
                discription: "Sony Music Artist Miguel Interview",
                srt: "newAssets/videos/SONYxMIGUEL_E5_Interview_CCs_16x9_1.mp4.vtt",
                artistName: "Miguel shares his thoughts on the WF-1000XM5",
              },
              {
                url: "newAssets/videos/SONY22_SEL_IANEASTWOOD_4K_16x9_WEB-SD_960x540p_25MB.mp4	",
                discription: "Choreographer and Audio Ambassador",
                makersName: "Ian Eastwood",
                srt: "newAssets/videos/IANEASTWOOD_CC'S_16x9.mp4.vtt",
              },
              {
                url: "newAssets/videos/SONY22_SEL_AUSTINMILLZ_4K_16x9_WEB-SD_960x540p_20MB.mp4",
                discription: "DJ and Audio Ambassador",
                makersName: "Austin Millz",
                srt: "newAssets/videos/AUSTINMILLZ_CC'S_16x9.mp4.vtt",
              },


            ],
          },

          makers_video_mobile_version: {
            portrait: [
              {
                url: "newAssets/videos/SONYxMIGUEL_E5_Interview_9x16_v1-SD_540x960_20MBp.mp4",
                discription: "Sony Music Artist Miguel Interview",
                makersName: "Miguel shares his thoughts on the WF-1000XM5",
                srt: "newAssets/videos/SONYxMIGUEL_E5_Interview_CCs_16x9_1.mp4.vtt",
              },
              {
                url: "newAssets/videos/SONY22_SEL_IANEASTWOOD_4K_9x16-SD_540x960p_25MB.mp4",
                discription: "Choreographer and Audio Ambassador",
                makersName: "Ian Eastwood",
                srt: "newAssets/videos/IANEASTWOOD_CC'S_16x9.mp4.vtt",
              },
              {
                url: "newAssets/videos/SONY22_SEL_AUSTINMILLZ_4K_9x16-SD_540x960p_20MB.mp4",
                discription: " DJ and Audio Ambassador",
                makersName: "Austin Millz",
                srt: "newAssets/videos/AUSTINMILLZ_CC'S_16x9.mp4.vtt",
              },

            ],
            landscape: [
              {
                url: "newAssets/videos/SONYxMIGUEL_E5_Interview_Edited_Text_16x9_v2-SD_960x540p_20MB.mp4",
                discription: "Sony Music Artist Miguel Interview",
                srt: "newAssets/videos/SONYxMIGUEL_E5 Interview_CC's_9x16_1.mp4.vtt",
                artistName: "Miguel shares his thoughts on the WF-1000XM5",
              },
              {
                url: "newAssets/videos/SONY22_SEL_IANEASTWOOD_4K_16x9_WEB-SD_960x540p.mp4",
                discription: "Choreographer and Audio Ambassador",
                makersName: "Ian Eastwood",
                srt: "newAssets/videos/IANEASTWOOD_CC'S_16x9.mp4.vtt",
              },
              {
                url: "newAssets/videos/SONY22_SEL_AUSTINMILLZ_4K_16x9_WEB-SD_960x540p.mp4",
                discription: "DJ and Audio Ambassador",
                makersName: "Austin Millz",
                srt: "newAssets/videos/AUSTINMILLZ_CC'S_16x9.mp4.vtt",
              },

            ],
          },
          images: [
            {
              discription: "Sony Music Artist Miguel Interview",
              makersName: "Miguel shares his thoughts on the WF-1000XM5",
              duration: "05:18",
              playlist_mob: "newAssets/images/miguel-artist-playlist-thumbnail.jpg",
              playList_desk: "newAssets/images/miguel-artist-playlist-thumbnail.jpg",
              img: "newAssets/images/Miguel_thumbnail_9x16.jpg",
              imgmob: "newAssets/images/Miguel_Thumbnail_44x44.jpg",
              alt_text: "miguel expressing his toughts",
            },
            {
              discription: "Choreographer and Audio Ambassador",
              makersName: "Ian Eastwood",
              duration: "05:44",
              playlist_mob:
                "newAssets/images/Ian_Eastwood_Thumbnail_16x9_186x96.jpg",
              playList_desk:
                "newAssets/images/Ian_Eastwood_Thumbnail_16x9_186x96.jpg",
              img: "newAssets/images/Ian_Eastwood_Thumbnail_650x705.jpg",
              imgmob: "newAssets/images/ian_estwood_thumbnail.jpg",
              alt_text:
                "Dancer/Choreographer, Ian Eastwood, wearing a hat, glasses and white Sony LinkBuds S earbuds.",
            },
            {
              discription: " DJ and Audio Ambassador",
              makersName: "Austin Millz",
              duration: "05:02",
              img: "newAssets/images/Austin Millz 650x705 (1).jpg",
              imgmob: "newAssets/images/Austin_Millz_Thumbnail.jpg",
              playlist_mob: "newAssets/images/Austin Millz 16x9_186x96.jpg",
              playList_desk: "newAssets/images/Austin Millz 16x9_186x96.jpg",
              alt_text:
                "DJ Austin Millz wearing sunglasses and dread locks and black WH-1000XM5 noise canceling headphones. Stares straight into camera.",
            },
          ],

          makers_text: "Discover the music the way it was meant to be heard",
          button_names: ["Watch Video", "pause"],
          maker_headText_one: "Artists",
          maker_headText_two_split_text: ["you", "know."],
          maker_headText_three: "STORIES",
          maker_headText_four: "You don't.",
          maker_subtext: "Get inside the minds of groundbreaking creators.",
        },
        artists: {
          artist: [
            {
              alt_text:
                "Singer/Songwriter Miguel wearing Silver Sony WF-1000XM5 headphones, red leather jacket with popped collar and large silver chain necklace. His hair in spiked dreadlocks and dyed red at the tips. He looks upwards with light shining on his face. ",
              img: "newAssets/images/688x632-Peso Pluma.jpg",
              img_banner:
                "newAssets/images/812x632-Video thumbnail.jfif",
              title: "For The Music",
              discription:
                "ULT Power Sound Series x Peso Pluma",
              artist_quote:
                "“I'm excited for the opportunity to collaborate with Sony's 'For The Music' campaign to continue opening pathways for Latin Music.”",
              artist_name: " -Peso Pluma",
            },
          ],
          product_image: {
            alt_text: "WF-1000XM5 Truly Wireless Earbuds",
            img: "newAssets/images/200x200-ULT_WEAR_Offwhite.png",
            product_img_link:
              "https://electronics.sony.com/audio/headphones/all-headphones/p/whult900n-b",
            product_catagory_link:
              "https://electronics.sony.com/audio/headphones/c/all-headphones",
            first_header: "Product Highlight",
            main_header: "	Massive Bass. ",
            Sub_header: "	Ultimate Vibe. ",

            SKU: "ULT WEAR  ",
            category: "All Headphones",
            main_category: "Headphones",
          },
          artists_video_desktop_version: {
            landscape: [
              {
                url: "newAssets/videos/BTS_H264_Textless_With_Black_End_Card_16x9_50Mb.mp4",
                discription: " ULT Power Sound Series x Peso Pluma",
                srt: "newAssets/videos/BTS_104.mp4.vtt",
                artistName: "For The Music",
              },
            ],
            portrait: [
              {
                url: "newAssets/videos/BTS_H264_Textless_With_Black_End_Card_9x16_15Mb.mp4",
                discription: " ULT Power Sound Series x Peso Pluma",
                srt: "newAssets/videos/BTS_104.mp4.vtt",
                artistName: "For The Music",
              },
            ],
          },

          artists_video_mobile_version: {
            landscape: [
              {
                url: "newAssets/videos/BTS_H264_Textless_With_Black_End_Card_16x9_50Mb.mp4",
                discription: "ULT Power Sound Series x Peso Pluma",
                srt: "newAssets/videos/BTS_104.mp4.vtt",
                artistName: "For The Music",
              },
            ],
            portrait: [
              {
                url: "newAssets/videos/BTS_H264_Textless_With_Black_End_Card_9x16_15Mb.mp4",
                discription: "ULT Power Sound Series x Peso Pluma",
                srt: "newAssets/videos/BTS_104.mp4.vtt",
                artistName: "For The Music",
              },
            ],
          },
          artist_text_heading_one: "For the Artists",
          artist_text_heading_two: "HOW miguel BREAKS THROUGH THE MUSIC",
          artist_text_heading_third: "Step inside Peso Pluma’s world of sound.",
        },
        join: {
          join_video_desktop_version: ["url1", "url2"],
          join_video_mobile_version: ["url1", "url2"],
          data: {
            success: {
              img: {
                mobile: {
                  portrait: "newAssets/images/Join_Success_Mob.jpg",
                  landscape: "newAssets/images/Join_Success_Web.jpg",
                },
                desktop: {
                  portrait: "newAssets/images/Join_Success_Web.jpg",
                  landscape: "newAssets/images/Join_Success_Web.jpg",
                },
              },
              refresh_link: "Sony is for the music.",

              heading_text: "You Are In",
              img_alt_text:
                "A girl enjoying music while wearing white Sony WH1000 XMARK5 Headphones.",
              subheading_Text:
                "Thanks for joining. Keep an eye on your inbox for exclusive offers, artist content, and giveaways you’ll find nowhere else. ",
            },
            error: {
              img: {
                mobile: {
                  portrait: "newAssets/images/Error_Mob.jpg",
                  landscape: "newAssets/images/Error_Web.jpg",
                },
                desktop: {
                  portrait: "newAssets/images/Error_Web.jpg",
                  landscape: "newAssets/images/Error_Web.jpg",
                },
              },
              heading_text: "Something is'nt right",
              img_alt_text: "",
              subheading_Text:
                "We’re sorry, we weren’t able to process your sign-up. Double-check all the fields are correct and try again.",
              refresh_link: "Refresh form",
            },
            Already_In: {
              img: {
                mobile: {
                  portrait: "newAssets/images/Join_Registered_Mob.jpg",
                  landscape: "newAssets/images/Join_Registered_Web.jpg",
                },
                desktop: {
                  portrait: "newAssets/images/Join_Registered_Web.jpg",
                  landscape: "newAssets/images/Join_Registered_Web.jpg",
                },
              },
              refresh_link: "Refresh form",
              img_alt_text:
                "A man proudly holding up black Sony WH1000 XMARK 5 Headphones.",
              heading_text: "You Are Already In",
              subheading_Text:
                "Looks like you’ve already subscribed. You’ll hear from us when we’ve got new offers, artist content, and giveaways.",
            },
          },
        },
        menifest: {
          desktop_images: [
            {
              ManifestName: "fortheear",
              img: "newAssets/images/01 WF-1000XM5_S_KeyVisual-Large.png",
              alt_text: "Sony WF-1000XM5 Earbuds",
              main_text:
                "Crafting a legacy of audio engineering to give you sound as the artist intended.  ",
              HeaderText: "for the Ear",
            },
            {
              ManifestName: "forthestage",
              img: "newAssets/images/Manifest_For_The_Stage.png",
              alt_text:
                "Tate McRae performing on a stage in front of a small crowd.",
              main_text:
                "Powering unforgettable performances that will forever change your idea of “live.” ",
              HeaderText: "for the stage",
            },
            {
              ManifestName: "forthestudio",
              img: "newAssets/images/Manifest_For_The_Studio.png",
              alt_text:
                "Black Sony MDR-7506 studio headphones sitting atop a music mixboard.",
              main_text:
                "Building tools that capture the artist vision truthfully and incredibly.",
              HeaderText: "for the studio",
            },
          ],
          mobile_images: [
            {
              ManifestName: "fortheear",
              img: "newAssets/images/01 WF-1000XM5_S_KeyVisual-Large.png",
              alt_text: "Sony WF-1000XM5 Earbuds",
              main_text:
                "Crafting a legacy of audio engineering to give you sound as the artist intended.  ",
              HeaderText: "for the Ear",
            },
            {
              ManifestName: "forthestage",
              img: "newAssets/images/Manifest_For_The_Stage.png",
              alt_text:
                "Tate McRae performing on a stage in front of a small crowd.",
              main_text:
                "Powering unforgettable performances that will forever change your idea of “live.” ",
              HeaderText: "for the stage",
            },
            {
              ManifestName: "forthestudio",
              img: "newAssets/images/Manifest_For_The_Studio.png",
              alt_text:
                "Black Sony MDR-7506 studio headphones sitting atop a music mixboard.",
              main_text:
                "Building tools that capture the artist vision truthfully and incredibly.",
              HeaderText: "for the studio",
            },
          ],
        },
        campaign: {
          campaign_video_desktop_version: {
            landscape: [
              {
                srt: "",
                alt_text: "",
                url: "newAssets/videos/FTM_Grad_16x9_HD.mp4",
              },
            ],
            portrait: [
              {
                srt: "",
                alt_text: "",
                url: "newAssets/videos/FTM_Grad_9x16_HD.mp4",
              },
            ],
          },

          campaign_video_mobile_version: {
            landscape: [
              {
                srt: "",
                alt_text: "",
                url: "newAssets/videos/FTM_Grad_16x9_HD.mp4",
              },
            ],
            portrait: [
              {
                srt: "",
                alt_text: "",
                url: "newAssets/videos/FTM_Grad_9x16_HD.mp4",
              },
            ],
          },
          campaign_text: "Discover the music the way it was meant to be heard",
          button_names: ["Watch Video", "pause"],
        },
        benifit: {
          benifitData: [
            // {
            //   cardClass: "card-round",
            //   imgClass: "benifits-card-one",
            //   images: {
            //     desktop: {
            //       cardsimages: {
            //         portrait:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //         landscape:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //       },
            //       popupdialogimages: {
            //         portrait:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //         landscape:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //       },
            //     },
            //     mobile: {
            //       cardsimages: {
            //         portrait:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //         landscape:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //       },
            //       popupdialogimages: {
            //         portrait:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //         landscape:
            //           "newAssets/images/ERA_Sony_EscapeTheNoise.jpg",
            //       },
            //     },
            //   },
            //   Special_title_Text: "Enter for a chance to Win",
            //   Special_heading_Text:
            //     "Score VIP passes for Miguel's album launch party in LA on August 26th ",
            //   alt_img_text:
            //     "Singer/Songwriter Miguel Pimentel wearing read leather suit, crouching down and singing to camera. ",
            //   Special_subHeading_Text: "To enter click join us. Enter \"LAUNCH\" as promo code. See Official rules in Explore+",
            //   sweepstakeCode: "LAUNCH",
            //   Special_content_Text:
            //     "Five lucky winners will score a VIP getaway to LA August 25-27 to see Miguel perform live at his album launch party. Sweepstakes will begin on 7/24 and end on 8/11. Winners will be notified no later than 8/21.",
            //   tncText:
            //     "Grand Prize Winners will be selected 8/14. The Grand Prize Winners will receive a trip to Los Angeles, California from August 24, 2023 – August 26, 2023. Trip includes: two (2) roundtrip coach airfare tickets (one for Winner and one for Winner’s Guest) from an airport nearby Winner’s home to LAX, two (2) consecutive nights stay in a hotel (double-occupancy room) selected by Sponsor, and roundtrip ground transportation from LAX to the hotel, and an exclusive concert with Miguel.",
            //   tncLink: "docs/Sony_Electronics_Audio_LA_VIP_Sweepstakes_Official_Rules.pdf"
            // },
            {
              cardClass: "card-gradient",
              imgClass: "benifits-card-two",
              images: {
                desktop: {
                  cardsimages: {
                    portrait:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                    landscape:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                  },
                  popupdialogimages: {
                    portrait:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                    landscape:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                  },
                },
                mobile: {
                  cardsimages: {
                    portrait:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                    landscape:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                  },
                  popupdialogimages: {
                    portrait:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                    landscape:
                      "newAssets/images/05 WF-1000XM5_B_case_open_one_side_out-Large.png",
                  },
                },
              },
              Special_title_Text: "Enter for a chance to Win",
              Special_heading_Text:
                "Win Sony's new noise canceling earbuds + limited edition For The Music merch",
              alt_img_text: "Sony WF-1000XM5 true wireless earbuds silver. ",
              Special_subHeading_Text:
                "To enter click Join Us and complete entry form. No code needed. See official rules in Explore+",
              Special_content_Text:
                "Every month five lucky winners will get truly wireless noise canceling earbuds and limited edition For The Music merchandise. Sweepstakes winners will be chosen monthly.",
              tncText:
                "Prize Packages consist of one (1) Sony WF-1000XM5  earbuds (ARV: $299.99) and one piece of Sony merchandise (ARV: $30 - $50).",
              tncLink:
                "docs/Sony_Electronics_Audio_LA_VIP_Sweepstakes_Official_Rules.pdf",
            },
          ],
          data: {
            success: {
              img: {
                mobile: {
                  portrait: "newAssets/images/Join_Success_Mob.jpg",
                  landscape: "newAssets/images/Join_Success_Web.jpg",
                },
                desktop: {
                  portrait: "newAssets/images/Join_Success_Web.jpg",
                  landscape: "newAssets/images/Join_Success_Web.jpg",
                },
              },
              heading_text: "You Are In",
              subheading_Text:
                "Thanks for joining. Keep an eye on your inbox for exclusive offers, artist content, and giveaways you'll find nowhere else. Sony is for the music.",
            },
            error: {
              img: {
                mobile: {
                  portrait: "newAssets/images/Error_Mob.jpg",
                  landscape: "newAssets/images/Error_Web.jpg",
                },
                desktop: {
                  portrait: "newAssets/images/Error_Web.jpg",
                  landscape: "newAssets/images/Error_Web.jpg",
                },
              },
              heading_text: "Something is'nt right",
              subheading_Text:
                "We're sorry, we weren't able to process your sign-up. Double-check all the fields are correct and try again.",
              refresh_link: "Refresh form",
            },
            Already_In: {
              img: {
                mobile: {
                  portrait: "newAssets/images/Join_Registered_Mob.jpg",
                  landscape: "newAssets/images/Join_Registered_Web.jpg",
                },
                desktop: {
                  portrait: "newAssets/images/Join_Registered_Web.jpg",
                  landscape: "newAssets/images/Join_Registered_Web.jpg",
                },
              },
              refresh_link: "Refresh form",
              heading_text: "You Are Already In",
              subheading_Text:
                "Looks like you’ve already subscribed. You’ll hear from us when we’ve got new offers, artist content, and giveaways.",
            },
          },
        },
        products: {
          productsInfo: [
            {
              cardColor: ["linear-gradient(0deg, #978FC0ff, #E0C7E5ff)", "#E8BF70", "#BAC9AB", "#A1BBC4", "#282C7F"],
              headphones: [
                {
                  main_link:
                    "https://electronics.sony.com/audio/headphones/c/all-headphones",

                  img: "newAssets/images/LinkBuds_S_x_Olivia_Rodrigo.png",
                  productDetails: {
                    alt: "Headphones WH-1000XM5 black.",
                    productName: "LinkBuds S x Olivia Rodrigo",
                    ProductTagLine: "Listen Like Olivia",
                    explore_link:
                      "https://electronics.sony.com/audio/headphones/truly-wireless-earbuds/p/wfls900nv-bundle",
                  },
                },
                {
                  main_link:
                    "https://electronics.sony.com/audio/headphones/c/all-headphones",

                  img: "newAssets/images/200x200-ULT_WEAR_ForestGrey.png",
                  productDetails: {
                    alt: "Headphones WH-1000XM5 black.",
                    productName: "ULT WEAR ",
                    ProductTagLine: "Boost The Bass.",
                    ProductTagSubLine: "Feel The Music.",
                    explore_link:
                      "https://electronics.sony.com/audio/headphones/all-headphones/p/whult900n-b",
                  },
                },
                {
                  main_link:
                    "https://electronics.sony.com/audio/headphones/c/all-headphones",

                  img: "newAssets/images/200x200 1_For_The_Now.png",
                  productDetails: {
                    alt: "Headphones WH-1000XM5 black.",
                    productName: "WH-1000XM5",
                    ProductTagLine: "Noise Canceling Headphones",
                    explore_link:
                      "https://electronics.sony.com/audio/headphones/headband/p/wh1000xm5-b",
                  },
                },

                {
                  // img: "assets/images/1_For_The_Now.png",
                  img: "newAssets/images/200x200 4_LinkBudsS_caseopen2_Earth_Blue-Mid.png",

                  main_link:
                    "https://electronics.sony.com/audio/headphones/c/all-headphones",
                  productDetails: {
                    alt: "Headphones LinkBuds S black.",
                    productName: "LinkBuds S",
                    ProductTagLine: "Noise Canceling Earbuds",
                    explore_link:
                      "https://electronics.sony.com/audio/headphones/truly-wireless-earbuds/p/wfls900n-l",
                  },
                },
                {
                  // img: "assets/images/1_For_The_Now.png",
                  img: "newAssets/images/200x200 01 WF-1000XM5_S_KeyVisual-Large.png",

                  main_link:
                    "https://electronics.sony.com/audio/headphones/c/all-headphones",
                  productDetails: {
                    alt: "Headphones WF-1000XM5  black",
                    productName: "WF-1000XM5 ",
                    ProductTagLine: "Premium Noise Canceling Earbuds",
                    explore_link:
                      "https://electronics.sony.com/audio/headphones/truly-wireless-earbuds/p/wf1000xm5-s",
                  },
                }
              ],
              speakers: [
                {
                  // img: "assets/images/SRS-XG300_H_cw__handleup_ copy.png",
                  img: "newAssets/images/200x200-ULT1.png",

                  main_link:
                    "https://electronics.sony.com/audio/speakers/c/all-speakers",
                  productDetails: {
                    alt: "SRS-XG300 Portable Wireless Speaker in gray.",
                    productName: "ULT Field 1",
                    ProductTagLine: " Powerful sound Compact design",
                    explore_link:
                      "https://electronics.sony.com/audio/speakers/all-speakers/p/srsult10-b",
                  },
                },
                {
                  // img: "assets/images/SRS-XG300_H_cw__handleup_ copy.png",
                  img: "newAssets/images/200x200-ULT7.png",

                  main_link:
                    "https://electronics.sony.com/audio/speakers/c/all-speakers",
                  productDetails: {
                    alt: "SRS-XG300 Portable Wireless Speaker in gray.",
                    productName: "Ult Field 7 ",
                    ProductTagLine: "Bring the party anywhere",
                    explore_link:
                      "https://electronics.sony.com/audio/speakers/party-speakers/p/srsult70",
                  },
                },
                {
                  // img: "assets/images/SRS-XG300_H_cw__handleup_ copy.png",
                  img: "newAssets/images/200x200-ULT TOWER10.png",

                  main_link:
                    "https://electronics.sony.com/audio/speakers/c/all-speakers",
                  productDetails: {
                    alt: "SRS-XG300 Portable Wireless Speaker in gray.",
                    productName: "	ULT Tower 10  ",
                    ProductTagLine: "Power of live-venue sound at your fingertips",
                    explore_link:
                      "https://electronics.sony.com/audio/speakers/all-speakers/p/srsult1000",
                  },
                },
                {
                  // img: "assets/images/SRS-XG300_H_cw__handleup_ copy.png",
                  img: "newAssets/images/200x200 SRS-XG300_H_cw__handleup_.png",

                  main_link:
                    "https://electronics.sony.com/audio/speakers/c/all-speakers",
                  productDetails: {
                    alt: "SRS-XG300 Portable Wireless Speaker in gray.",
                    productName: "SRS-XG300",
                    ProductTagLine: "Portable Wireless Speaker",
                    explore_link:
                      "https://electronics.sony.com/audio/speakers/wireless-speakers/p/srsxg300-hz",
                  },
                },
                {
                  // img: "assets/images/SRS-XV800_pers copy.png",
                  img: "newAssets/images/200x200 SRS-XV800_pers.png",
                  main_link:
                    "https://electronics.sony.com/audio/speakers/c/all-speakers",
                  productDetails: {
                    alt: "Wireless Party Speaker XV800 black.",
                    productName: "SRS-XV800",
                    ProductTagLine: "Wireless Party Speaker",
                    explore_link:
                      "https://electronics.sony.com/audio/speakers/party-speakers/p/srsxv800",
                  },
                },
              ],
              "Pro Audio": [
                {
                  img: "newAssets/images/200x200 MDR-7506.png",
                  main_link:
                    "https://electronics.sony.com/audio/professional-audio/c/all-professional-audio",
                  productDetails: {
                    alt: "Sony MDR-7506 professional studio headphones",
                    productName: "MDR-7506",
                    ProductTagLine: "Professional Studio Headphones",
                    explore_link:
                      "https://electronics.sony.com/audio/headphones/all-headphones/p/mdr7506",
                  },
                },
                {
                  img: "newAssets/images/C80_frontwithcradle-Large.png",
                  main_link:
                    "https://electronics.sony.com/audio/professional-audio/c/all-professional-audio",
                  productDetails: {
                    alt: "Sony C80 professional studio Microphone",
                    productName: "C80",
                    ProductTagLine: "Professional Studio Microphone",
                    explore_link:
                      "https://electronics.sony.com/audio/professional-audio/all-professional-audio/p/c80",
                  },
                },
              ],
            },
          ],
        },
      },
    },
  },
};

export default dictonary;
