
import styled from "styled-components";

export const HeroSliderWrapper = styled.div`

.swiper-pagination {
	position: absolute;
	width: auto !important; 
}
.swiper-Nav{
	margin-top:24px
}
.nav-tabs-product{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0px 80px 
}
.title-timer-song{
  align-items:center;
  display:flex;
  padding:0px 8px 8px 8px;
  justify-content:space-between;
}
.swiper-Nav { 
  padding-bottom: 36px;
}

// .slider-bulltes ul{
//   margin-right:80px
// }

.slider-bulltes button{
  border-radius:30px !important;
  margin-top:0px !important;
  padding:7px 16px!important;
}
.video-playlist {
    border-radius: 8px;
    cursor:pointer;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
}
.prev-next-audio {
	display: flex;
    justify-content: flex-end;
}
.prev-swip{
  border: 1px solid #000;
  color: #000;
  padding: 0px 22px;
  display: flex;
  margin-right:12px;
  border-radius: 50px;
  width: 56px;
  height: 36px;
  align-items: center;
  justify-content: center;
}
.next-swip{
  border: 1px solid #000;
  color: #000;
  padding: 0px 22px;
  display: flex;
  border-radius: 50px;
  width: 56px;
  height: 36px;
  align-items: center;
  justify-content: center;
}

// @media screen and (max-width: 767px) {
//   .nav-tabs-product{
//   flex-direction:column-reverse;
//   padding:0 16px
//   }
//   .slider-bulltes ul{
//     margin-right:0px !important;
//   }
// }
  `

export const CustomeSwiperstylesWrapper = styled.div`
  .slider-bulltes button{
    background: transparent !important;
    border:1px solid #000 !important
  }
  .slider-bulltes button svg{
    color: #000 !important;
  
  }
  
`