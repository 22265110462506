import { Swiper } from "swiper/react";
import React, { useRef } from "react";
import { HeroSliderWrapper } from "./styles";
// import { isMobile} from "react-device-detect";
// Import Swiper styles
import "swiper/swiper.min.css";
import { isTouchScreen } from "../../utils";
import { Box } from "@mui/material";

import CustomeBottomNav from "../UI-components/customeBottomNav";
export default (props) => {
  const [isMobile, setIsMobile] = React.useState();
  const [isTablet, setIsTablet] = React.useState();
  const [orientation, setOrientation] = React.useState("portrait");
  const [index, setIndex] = React.useState(0);
  const swiperRef = useRef(null);
  React.useEffect(() => {
    setIndex(0);
  }, [orientation]);

  function resize() {
    if (window.innerWidth < 650) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  window.onresize = resize;
  const next = () => {
    setIsTablet(true);
    if (window.innerWidth > 666 && window.innerHeight < 1367) {
      if (props.numberofSlides - 2 > index) {
        swiperRef.current.swiper.slideToLoop(index + 2);
        setIndex((index) => index + 2);
      } else {
        swiperRef.current.swiper.slideToLoop(0);
        setIndex(0);
      }
      // swiperRef.current.swiper.slideNext();
    } else {
      setIsTablet(false);
      if (props.numberofSlides - 1 > index) {
        // swiperRef.current.swiper.slideToLoop(index + 1);
        setIndex((index) => index + 1);
      } else {
        // swiperRef.current.swiper.slideToLoop(0);
        setIndex(0);
      }
      swiperRef.current.swiper.slideNext();
    }
  };
  const prev = () => {
    if (window.innerWidth > 666 && window.innerHeight < 1367) {
      setIsTablet(true);
      if (index === 0) {
        setIndex(props.numberofSlides - 2);
        swiperRef.current.swiper.slideToLoop(props.numberofSlides - 1);
      } else {
        swiperRef.current.swiper.slideToLoop(index - 1);
        setIndex((index) => index - 1);
      }
    } else {
      if (index === 0) {
        setIsTablet(false);
        setIndex(props.numberofSlides - 1);
        // swiperRef.current.swiper.slideToLoop(props.numberofSlides - 1);
      } else {
        // swiperRef.current.swiper.slideToLoop(index - 1);
        setIndex((index) => index - 1);
      }
      swiperRef.current.swiper.slidePrev();
    }
  };

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  const ontouchcancel = (e, info) => {
    setIndex(e.activeIndex);
  };

  const createswiper = () => (
    <>
      <Swiper
        keyboard={{
          enabled: true,
          onlyInViewport: false,
        }}
        allowTouchMove={props.contentSlides.length > 2}
        allowTouchMove={isTouchScreen()}
        loop
        onTransitionEnd={(e) => ontouchcancel(e, "hi")}
        loopedSlides
        breakpoints={{
          360: {
            slidesPerView: props.from === "benifit" ? 1 : 1.2,
            spaceBetween: props.from == "product" ? 20 : 10,
            slidesOffsetAfter: 10,
          },
          374: {
            slidesPerView: props.from === "benifit" ? 1 : 1.2,

            spaceBetween: props.from == "product" ? 20 : 10,
            slidesOffsetAfter: 10,
          },
          660: {
            slidesPerView: 2.2,
            spaceBetween: 20,
            slidesOffsetAfter: 20,
          },
          768: {
            slidesPerView: props.from == "product" ? 2.3 : 2.5,
            loopedSlides: props.from == "product" ? 2 : 3,
            slidesPerGroup: 2,
            slidesOffsetAfter: 20,
            spaceBetween: 10,
          },
          900: {
            slidesPerView: props.from == "product" ? 3.15 : 2.5,
            loopedSlides: props.from == "product" ? 2 : 3,
            slidesPerGroup: 2,
            slidesOffsetAfter: 5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView:
              props.from == "product"
                ? 3
                : orientation === "landscape"
                  ? 2.3
                  : 2.5,
            spaceBetween: 20,
            slidesOffsetAfter: 20,
          },
          1280: {
            slidesPerView:
              props.from == "product"
                ? 3.3
                : orientation === "landscape"
                  ? 2.3
                  : 2.8,
            // loopedSlides: props.from == "product" ? 3 : 3,
            spaceBetween: 15,
            slidesOffsetAfter: 10,
          },
          1366: {
            slidesPerView:
              props.from == "product"
                ? 3.5
                : orientation === "landscape"
                  ? 2.3
                  : 2.9,
            // loopedSlides: props.from == "product" ? 3 : 3,
            spaceBetween: 15,
          },
          1530: {
            slidesPerView:
              props.from == "product"
                ? 3.5
                : orientation === "landscape"
                  ? 2.3
                  : 2.8,
            // loopedSlides: props.from == "product" ? 3 : 3,
            spaceBetween: 15,
          },

          1650: {
            slidesPerView: props.from == "product" ? 3.5 : 3.2,
            // loopedSlides: props.from == "product" ? 3 : 3,
            spaceBetween: 20,
          },
          1720: {
            slidesPerView: props.from == "product" ? 4.5 : 3.7,
            // loopedSlides: props.from == "product" ? 3 : 3,
            spaceBetween: 20,
          },
          1920: {
            slidesPerView: props.from == "product" ? 4.5 : 3.9,
            // loopedSlides: props.from == "product" ? 3 : 3,
            spaceBetween: 20,
          },
        }}
        // centeredSlides={true}
        direction={props.direction ? props.direction : "horizontal"}
        ref={swiperRef}
        touchMoveStopPropagation
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
      >
        {props.contentSlides}
      </Swiper>
      <Box className="swiper-Nav">
        <Box className="swiper-dotes">
          <div className="swiper-pagination"></div>
        </Box>


        {window.innerWidth < 560 &&
          window.innerWidth < window.innerHeight &&
          window.innerHeight < 1280 &&
          props.contentSlides.length >= 2 &&
          props.from === "product" && (
            <CustomeBottomNav
              next={() => next()}
              from={props.from}
              prev={() => prev()}
              index={index}
              data={
                window.innerWidth > 550 &&
                  window.innerWidth < 1024 &&
                  window.innerWidth < window.innerHeight &&
                  window.innerHeight < 1280
                  ? [1, 2]
                  : props.contentSlides
              }
            />
          )}
        {window.innerHeight < 560 &&
          window.innerWidth > window.innerHeight &&
          window.innerWidth < 1280 &&
          props.contentSlides.length > 2 &&
          props.from === "product" && (
            <CustomeBottomNav
              next={() => next()}
              from={props.from}
              prev={() => prev()}
              index={index}
              data={
                window.innerHeight < 560 &&
                  window.innerWidth > window.innerHeight &&
                  window.innerWidth < 1280
                  ? [1, 2]
                  : props.contentSlides
              }
            />
          )}
        {window.innerHeight > 560 &&
          window.innerWidth < window.innerHeight &&
          window.innerWidth >= 1024 &&
          props.contentSlides.length > 2 &&
          props.from === "product" && (
            <CustomeBottomNav
              next={() => next()}
              from={props.from}
              prev={() => prev()}
              index={index}
              data={
                window.innerHeight < 560 &&
                  window.innerWidth > window.innerHeight &&
                  window.innerWidth < 1280
                  ? [1, 2]
                  : props.contentSlides
              }
            />
          )}

        {window.innerWidth >= 1024 &&
          window.innerWidth <= 1920 &&

          window.innerWidth > window.innerHeight &&
          window.innerHeight <= 1366 &&
          orientation == "portrait" &&
          props.contentSlides.length > 2 &&
          props.from === "product" && (
            <CustomeBottomNav
              next={() => next()}
              from={props.from}
              prev={() => prev()}
              index={index}
              data={
                window.innerWidth > 550 &&
                  window.innerWidth > 1024 &&
                  window.innerWidth > window.innerHeight &&
                  window.innerHeight < 1366
                  ? [1, 2]
                  : props.contentSlides
              }
            />
          )}
        {window.innerWidth > 560 &&
          window.innerWidth < window.innerHeight &&
          window.innerHeight < 1280 &&
          props.contentSlides.length > 2 &&
          props.from === "product" && (
            <CustomeBottomNav
              next={() => next()}
              from={props.from}
              prev={() => prev()}
              index={index}
              data={
                window.innerWidth > 550 &&
                  window.innerWidth < 1024 &&
                  window.innerWidth < window.innerHeight &&
                  window.innerHeight < 1280
                  ? [1, 2]
                  : props.contentSlides
              }
            />
          )}
        {window.innerWidth < 560 && props.from === "benifit" && props.contentSlides.length > 1 && (
          <CustomeBottomNav
            next={() => next()}
            prev={() => prev()}
            index={index}
            from={props.from}
            data={props.contentSlides.props.children}
          />
        )}
        {window.innerWidth > 550 && window.innerWidth < 1024 &&
          props.numberofSlides > 3 &&
          props.from === "makerplaylist" && (
            <CustomeBottomNav
              next={() => next()}
              prev={() => prev()}
              index={index}
              from={props.from}
              data={[1, 2]}
            />
          )}
      </Box>
    </>
  );

  return (
    <HeroSliderWrapper>
      {orientation == "landscape" && createswiper()}
      {orientation == "portrait" && createswiper()}
    </HeroSliderWrapper>
  );
};
