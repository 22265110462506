import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { iOS } from "../../utils";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import ClosedCaptionOffIcon from "@mui/icons-material/ClosedCaptionOff";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { HeroVideoWrapper, VideoplayerOverlay } from "./styles";

function LinearProgressWithLabel(props) {
  const [volumeLevel, setVolumelevel] = useState(0);
  const [prevol, setPrevVol] = useState(0.0);
  const [adjustVolume, setAdjustVolume] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(props.index);
  const [videoState, setVideoState] = useState(props.videoEnded);
  const [mute, setMute] = useState(true);
  const [captionClickswitch, setCationClickSwitch] = useState(false);
  const [iosVolume, setIosVolume] = useState(true);
  const [overlayVolume, setOverlayVolume] = useState(null);
  const volumeSliderRef = useRef(null);
  useEffect(() => {}, [
    props.data,
    props.totalDuration,
    props.currentSeek,
    props.videoEnded,
  ]);
  useEffect(() => {
    setVideoState((videoState) => !videoState);
  }, [props.videoEnded]);
  useEffect(() => {
    hideVolume();
  }, [volumeSliderRef]);

  const isIos = iOS();
  const setVolume = (e) => {
    if (e.target.value / 100 <= 0) {
      setAdjustVolume(false);
    }
    setPrevVol(e.target.value / 100);
    setVolumelevel(e.target.value / 100);
    setMute(false);
    props.volume(e.target.value / 100);
  };
  const next = () => {
    if (props.index === props.data.landscape.length) return;
    if (props.data.landscape.length > currentIndex) {
      setCurrentIndex((currentIndex) => currentIndex + 1);
      props.setIndex(currentIndex + 1);
    }
  };
  const previous = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      props.setIndex(currentIndex - 1);
    }
  };
  const showHideVolumeAdjust = () => {
    props.volume(0);
    setMute(true);
    setVolumelevel(0);
  };

  const setMuteVolume = () => {
    if (!isIos) {
      props.volume(prevol);
      setVolumelevel(prevol);
    }
  };

  const handlePlayPause = () => {
    props.handlePause();
    setVideoState((videoState) => !videoState);
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    // var hDisplay = h > 0 ? h + (h == 1 ? " " : ":") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : s > 0 ? "0:" : "0:";
    var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "0";
    return mDisplay + sDisplay;
  }

  const hideVolume = () => {
    if (!isIos) {
      clearTimeout(overlayVolume);

      let overlayButton = volumeSliderRef.current;
      overlayButton.style.visibility = "visible";
      overlayButton.style["pointer-events"] = "auto";

      let i = setTimeout(function () {
        let overlayButton = volumeSliderRef.current;
        overlayButton.style.visibility = "hidden";
        overlayButton.style["pointer-events"] = "none";
      }, 2950);
      setOverlayVolume(i);
    }
  };

  const setMuteVolumeios = (action) => {
    if (action == "up") {
      setIosVolume(true);
      props.volume(0);
    } else {
      setIosVolume(false);
      props.volume(1);
    }
  };
  const captionClick = () => {
    setCationClickSwitch((captionClick) => !captionClick);

    props.caption();
  };

  return (
    <VideoplayerOverlay>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          top: props.data && props.data.landscape.length > 1 ? "28px" : "0px",
          position:
            props.data && props.data.landscape.length > 1 ? "relative" : "",
        }}
      >
        <Box
          className={
            props.data.landscape.length > 1
              ? "audio-player-main"
              : "audio-player-margin"
          }
        >
          <Box className="cc-song-name">
            <Box>
              <Typography
                variant="heading "
                className="song-name"
                color="primary"
                style={{ display: "block" }}
              >
                {props.currentVid.artistName || props.currentVid.makersName}
              </Typography>
              <Typography
                variant="body2"
                className="singer-name"
                color="primary"
                style={{ display: "block" }}
              >
                {props.currentVid.discription}
              </Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                disableRipple
                tabIndex={0}
                className={"caption-btn"}
                style={{
                  background: captionClickswitch ? "#fff" : "",

                  borderRadius: "7px",
                  padding: "0px",
                }}
                aria-label={
                  captionClickswitch ? "Open Caption" : "Hide Caption"
                }
                onClick={() => captionClick()}
              >
                <ClosedCaptionOffIcon
                  style={{ color: captionClickswitch ? "#000" : "" }}
                />
              </IconButton>
              <IconButton
               tabIndex={0}
                disableRipple
                aria-label="Adjust Volume"
                style={{ position: "relative" }}
              >
                {!isIos && (
                  <div
                    onMouseMove={() => hideVolume()}
                    style={{
                      height:
                        window.innerHeight < 400 &&
                        props.orientation === "landscape"
                          ? "80px"
                          : "90px",
                      position: "absolute",
                      bottom: "15px",
                      transition: "all 1s ease-in-out",
                    }}
                  >
                    <div ref={volumeSliderRef}>
                      <VerticalSlider
                        setVolume={setVolume}
                        volumeLevel={volumeLevel}
                      />
                    </div>
                  </div>
                )}
                {!isIos && volumeLevel * 100 > 0 && (
                  <VolumeUpOutlinedIcon
                    color="primary"
                    onMouseMove={() => hideVolume()}
                    onMouseDown={showHideVolumeAdjust}
                  />
                )}
                {!isIos && volumeLevel * 100 <= 0 && !adjustVolume && (
                  <VolumeOffIcon
                    onMouseMove={() => hideVolume()}
                    onMouseDown={() => setMuteVolume()}
                  />
                )}
                {isIos && !iosVolume && (
                  <VolumeUpOutlinedIcon
                    onClick={() => setMuteVolumeios("up")}
                  />
                )}
                {isIos && iosVolume && (
                  <VolumeOffIcon onClick={() => setMuteVolumeios("down")} />
                )}
              </IconButton>
            </Box>
          </Box>

          <Box style={{ width: "100%" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <Slider
                aria-label="volume adjust slider"
                size="small"
                value={props.currentSeek}
                min={0}
                step={1}
                max={props.totalDuration}
                onChange={(e) => props.handleOnSeekChange(e)}
                sx={{
                  color: "dark" === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                  height: 4,
                  "& .MuiSlider-thumb": {
                    width: 8,
                    height: 8,
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&:before": {
                      boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: `0px 0px 0px 8px ${
                        "dark" === "dark"
                          ? "rgb(255 255 255 / 16%)"
                          : "rgb(0 0 0 / 16%)"
                      }`,
                    },
                    "&.Mui-active": {
                      width: 20,
                      height: 20,
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
              />

              {/* <LinearProgress variant="determinate" {...props} /> */}
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="body2" color="primary">
                {secondsToHms(props.currentSeek)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="primary">
                -{secondsToHms(props.totalDuration - props.currentSeek)}
              </Typography>
            </Box>
          </Box>
          <Box
            className="play-next-prev"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {props.data && (
              <IconButton
              tabIndex={0}
                disableRipple
                aria-label="skip to previous"
                onClick={() => previous()}
                disabled={currentIndex === 0}
              >
                <SkipPreviousOutlinedIcon
                  color="primary"
                  className={currentIndex === 0 ? "next-prev-disabled" : ""}
                />
              </IconButton>
            )}
            <Button
              variant="contained"
              disableRipple
              tabIndex={0}
              style={{
                height:
                  props.orientation === "landscape" && window.innerHeight < 560
                    ? "40px"
                    : "",
              }}
              className="hero-P-pause-btn font-poppins-regular"
              onClick={() => handlePlayPause()}
              aria-label={videoState ? "Pause" : "Play"}
            >
              {videoState ? "Pause" : "Play"}
            </Button>
            {props.data && (
              <IconButton
                disableRipple
                tabIndex={0}
                aria-label="skip to next"
                onClick={() => next()}
                disabled={currentIndex === props.data.landscape.length - 1}
              >
                <SkipNextOutlinedIcon
                  color="primary"
                  className={
                    currentIndex === props.data.landscape.length - 1
                      ? "next-prev-disabled"
                      : ""
                  }
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </VideoplayerOverlay>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel(props) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={1} {...props} />
    </Box>
  );
}

export function VerticalSlider(props) {
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const handleVolumeChange = (e) => {
    props.setVolume(e);
  };

  return (
    <Box
      sx={{
        height: "62px",
      }}
    >
      <Slider
        sx={{
          '& input[type="range"]': {
            WebkitAppearance: "slider-vertical",
          },
        }}
        onChange={(e) => handleVolumeChange(e)}
        orientation="vertical"
        defaultValue={props.mute ? 0 : props.volumeLevel}
        aria-label="Temperature"
        valueLabelDisplay="auto"
        onKeyDown={preventHorizontalKeyboardNavigation}
      />
    </Box>
  );
}
