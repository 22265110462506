import { Box } from "@mui/material";
import React from "react";
import { SliderWrapperPopup } from "./style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import dictonary from "../../../dictionary";
import { isTouchScreen } from "../../utils";
export default function OnSubmitComponent(props) {
  const [isMobile, setIsMobile] = React.useState();

  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    let ele = document.querySelector("#swipe");
    if (ele) {
      setElement(ele);
    }
  }, [element]);

  const [orientation, setOrientation] = React.useState("portrait");
  React.useEffect(() => {}, [window.innerWidth, orientation ,props.currentComponent]);

  let device = window.innerWidth > 550 ? "desktop" : "mobile";
  // let orientation = window.innerWidth > 550 ? "landscape" : "portrait";

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });
  let img = props.data.img[device][orientation];

  let image_data = dictonary.Pages.landing_page.metadata;

  function detectswipe(el, func) {
    if (isTouchScreen) {
      let swipe_det = new Object();

      swipe_det.sX = 0;
      swipe_det.sY = 0;
      swipe_det.eX = 0;
      swipe_det.eY = 0;
      var min_x = 50; //min x swipe for horizontal swipe
      var max_x = 50; //max x difference for vertical swipe
      var min_y = 50; //min y swipe for vertical swipe
      var max_y = 50; //max y difference for horizontal swipe
      var direc = "";
      let ele = el;
      ele.addEventListener(
        "touchstart",
        function (e) {
          var t = e.touches[0];
          swipe_det.sX = t.screenX;
          swipe_det.sY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();
          var t = e.touches[0];
          swipe_det.eX = t.screenX;
          swipe_det.eY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchend",
        function (e) {
          //horizontal detection
          if (
            (swipe_det.eX - min_x > swipe_det.sX ||
              swipe_det.eX + min_x < swipe_det.sX) &&
            swipe_det.eY < swipe_det.sY + max_y &&
            swipe_det.sY > swipe_det.eY - max_y
          ) {
            if (swipe_det.eX > swipe_det.sX) direc = "r";
            else direc = "l";
          }
          //vertical detection
          if (
            (swipe_det.eY - min_y > swipe_det.sY ||
              swipe_det.eY + min_y < swipe_det.sY) &&
            swipe_det.eX < swipe_det.sX + max_x &&
            swipe_det.sX > swipe_det.eX - max_x
          ) {
            if (swipe_det.eY > swipe_det.sY) direc = "d";
            else direc = "u";
          }

          if (direc != "") {
            if (typeof func == "function") func(el, direc);
          }
          direc = "";
        },
        false
      );
    }
  }

  function myfunction(el, d) {
    if (!element) return;
    if (d == "d") {
      props.close();
    }
  }

  element !== null && detectswipe(element, myfunction);

  function resize() {
    if (window.innerWidth < 650) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  window.onresize = resize;

  return (
    <SliderWrapperPopup style={{ width: "100%", padding: "0" }} id={"swipe"}>
      <Box className="you-are-in">
        <Box variant="outlined">
          <Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              {window.innerWidth > 650 &&(
                <IconButton
                  edge="start"
                  color="inherit"
                  style={{ padding: 0 }}
                  onClick={props.close}
                  aria-label="Close Form"
                  id="dialogSlideTrue"
                >
                  <KeyboardArrowDownIcon
                    style={{ fontSize: "28px", padding: "0px", zIndex: 9 }}
                  />
                </IconButton>
              )}
            </Box>

            <Box className="content-msg-div">
              {window.innerWidth < 650 && (
                <div className="drawer-close-icon">
                  <Box className="Slide-Swiper-div" onClick={props.close}>
                    <Box className="swipe"></Box>
                  </Box>
                </div>
              )}
              <img
                loading="lazy"
                src={image_data.app_logo_img}
                //  src="./images/for_the_music.png"
                alt={"for the music image"}
              />
              <p></p>
            </Box>
          </Box>         
        </Box>
        <Box className="insider-form-div">
          <p className="message-head font-Zuume-56">{}</p>

          {props.data.heading_text !== "Something is'nt right" && (
            <p className="message-head font-Zuume-56">
              {props.data.heading_text}
            </p>
          )}

          {props.data.heading_text === "Something is'nt right" && (
            <>
              <p className="message-head font-Zuume-56">Something</p>
              <p className="is-not-right font-Zuume-56">is'nt right</p>
            </>
          )}

          <Box className="insider-form-div">
            <Box className="start-text">
              <Box className="subtext-width">
                <p className="font-poppins-regular">
                  {props.data.subheading_Text}
                </p>
                {props.data.heading_text !== "You Are In" && (
                  <div
                    className="refresh-text"
                    onClick={() => props.reset()}
                    style={{
                      color: "inherit",
                      marginTop: "24px",
                      cursor: "pointer",
                      zIndex: "999",
                      position: "relative",
                    }}
                  >
                    {props.data.refresh_link}
                  </div>
                )}
                {props.data.heading_text === "You Are In" && (
                  <div
                    className="refresh-text"
                    style={{
                      color: "inherit",
                      marginTop: "24px",
                      zIndex: "999",
                      position: "relative",
                    }}
                  >
                    {props.data.refresh_link}
                  </div>
                )}
              </Box>
              {window.innerWidth > 600 && orientation === "portrait" && (
                <Box
                  className="circle-div-join-web"
                  style={{
                    // width: window.innerWidth / 1.9,
                    //   height: window.innerWidth / 1.9,
                    //   bottom: -(window.innerWidth /2),
                    //   right: -(window.innerWidth / 40),
                  }}
                >
                  <Box
                    className="circle-img-join-web"
                    style={{ backgroundImage: `url(${img})` }}
                  ></Box>
                </Box>
              )}

              {window.innerWidth > 600 && orientation === "landscape" && (
                <Box
                  className="circle-div-join-web"
                  style={
                    {
                      // width: window.innerWidth / 1.9,
                      // height: window.innerWidth / 1.9,
                      // bottom: -(window.innerWidth /2),
                      // right: -(window.innerWidth / 40),
                    }
                  }
                >
                  <Box
                    className="circle-img-join-web"
                    style={{ backgroundImage: `url(${img})` }}
                  ></Box>
                </Box>
              )}

              {window.innerWidth < 600 && (
                <Box className="circle-div-join">
                  <Box
                    className="circle-img-join"
                    style={{ backgroundImage: `url(${img})` }}
                  ></Box>
                </Box>
              )}             
            </Box>
          </Box>
        </Box>
      </Box>
    </SliderWrapperPopup>
  );
}
