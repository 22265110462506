import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { HeroSliderWrapper } from "./style";

import JointoForm from "./joinme2form";
import SliderDialog from "../UI-components/dialogSlider";

import OnSubmitComponent from "./OnSubmitComponent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import dictonary from "../../../dictionary";
import { isTouchScreen } from "../../utils";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HeroSlider(props) {
  const [open, setOpen] = React.useState(props.open);
  const [orientation, setOrientation] = React.useState("portrait");
  const [currentComponent, setCurrentComponent] = useState("join");
  React.useEffect(() => {}, [orientation ,currentComponent]);

  useEffect(() => {
    let ele = document.querySelector("#heroswipeme");
    if (ele) {
      setElement(ele);
    }
  }, [element]);
  useEffect(() => {
    if (props.open && props.isFullScreen == false) {
      setOpen(true);
      props.setSkipbottononformfill("hide");
      props.setCurrentScrollpos(
        document.documentElement.scrollTop || document.body.scrollTop
      );
    }
  }, [props.open]);

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  let data = dictonary && dictonary.Pages.landing_page.components.join.data;
  const handleClickOpen = (e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "join_us",
      buttonText: e.target.textContent,
    });
    props.setSkipbottononformfill("hide");
    setOpen(true);

    props.setCurrentScrollpos(
      document.documentElement.scrollTop || document.body.scrollTop
    );
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentComponent("join");
    props.closeJoinus();
    props.setSkipbottononformfill("show");
    props.setCurrentScrollpos(props.currentScrollpos);
  };
  const showopen = () => {
    let docbodyheight = JSON.parse(JSON.stringify(document.body.offsetHeight));
    let windowInnerHeight = JSON.parse(JSON.stringify(window.innerHeight));
    let scrollywindow = Math.round(JSON.parse(JSON.stringify(window.scrollY)));

    if (
      windowInnerHeight + scrollywindow >= docbodyheight &&
      props.isGredientPlaying &&
      props.isFullScreen == false
    ) {
      setOpen(true);
      props.setCurrentScrollpos(
        document.documentElement.scrollTop || document.body.scrollTop
      );
    }
  };

  window.addEventListener("scroll", showopen);

  const currentComponentFun = (name) => {
    setCurrentComponent(name);
  };
  const reset = () => {
    setCurrentComponent("join");
  };

  const [element, setElement] = React.useState(null);
  function detectswipe(el, func) {
    if (isTouchScreen) {
      let swipe_det = new Object();
      swipe_det.sX = 0;
      swipe_det.sY = 0;
      swipe_det.eX = 0;
      swipe_det.eY = 0;
      var min_x = 10; //min x swipe for horizontal swipe
      var max_x = 100; //max x difference for vertical swipe
      var min_y = 10; //min y swipe for vertical swipe
      var max_y = 100; //max y difference for horizontal swipe
      var direc = "";
      let ele = el;
      ele.addEventListener(
        "touchstart",
        function (e) {
          var t = e.touches[0];
          swipe_det.sX = t.screenX;
          swipe_det.sY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();
          var t = e.touches[0];
          swipe_det.eX = t.screenX;
          swipe_det.eY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchend",
        function (e) {
          //horizontal detection
          if (
            (swipe_det.eX - min_x > swipe_det.sX ||
              swipe_det.eX + min_x < swipe_det.sX) &&
            swipe_det.eY < swipe_det.sY + max_y &&
            swipe_det.sY > swipe_det.eY - max_y
          ) {
            if (swipe_det.eX > swipe_det.sX) direc = "r";
            else direc = "l";
          }
          //vertical detection
          if (
            (swipe_det.eY - min_y > swipe_det.sY ||
              swipe_det.eY + min_y < swipe_det.sY) &&
            swipe_det.eX < swipe_det.sX + max_x &&
            swipe_det.sX > swipe_det.eX - max_x
          ) {
            if (swipe_det.eY > swipe_det.sY) direc = "d";
            else direc = "u";
          }

          if (direc != "") {
            if (typeof func == "function") func(el, direc);
          }
          direc = "";
        },
        false
      );
    }
  }

  function myfunction(el, d) {
    if (d == "u") {
      setOpen(true);
    } else {
      if (d == "d") {
      }
    }
  }

  element != null && detectswipe(element, myfunction);
  let image_data = dictonary.Pages.landing_page.metadata;

  return (
    <HeroSliderWrapper>
      <Box
        style={{
          width: "100%",
          maxWidth: "1920px",
        }}
      >  {window.innerWidth < 650 && (
        <Box  onClick={props.close}></Box>
      )}
        <Box
          variant="outlined"
          onClick={(e) => handleClickOpen(e)}
          id="heroswipeme"
        >
          <Box className="hero-slider-content">
          
            <Box className="arrow-icon">
              {window.innerWidth > 650 && (
                <IconButton
                  style={{ width: "100%", padding: 0, fontSize: "28px" }}
                  edge="start"
                  color="inherit"
                  onClick={props.close}
                  aria-label="Open Form"
                >
                  {/* <CloseIcon /> */}

                  <KeyboardArrowUpIcon
                    style={{ padding: 0, fontSize: "28px" }}
                  />
                </IconButton>
              )}
            </Box>
            <Box className="join-img">
              <img
                loading="lazy"
                src={image_data.app_logo_img}
                // src="./images/for_the_music.png"
                alt={"for the music image"}
              />

              <p className=" font-poppins-regular join-design-text">Join Us</p>
            </Box>
          </Box>
        </Box>
        <SliderDialog
          open={open}
          sliderHeight="inherit"
          close={() => handleClose}
          className={`safari_only ${
            currentComponent === "join" ? "jointoForm" : ""
          }`}
          style={{
            backgroundColor: "#000",
            margin: "0 auto",
            maxWidth: "1920",
            borderRadius: "18px 18px 0px 0px",
            // padding: currentComponent !== "join" ? "0px" : "0px 80px 24px 80px",
            height:
              window.innerWidth > 1550
                ? currentComponent === "join"
                  ? "auto"
                  : "86vh"
                : window.innerWidth > 600 &&
                  window.innerWidth < window.innerHeight
                ? "62vh"
                : window.innerWidth > 600 &&
                  window.innerWidth > window.innerHeight
                ? window.innerHeight < 550
                  ? "80vh"
                  : "87vh"
                : currentComponent !== "join"
                ? "86vh"
                : "88vh",
            overflowY: currentComponent === "join" ? "scroll" : "hidden",
          }}
          contentComponent={
            currentComponent === "join" ? (
              <JointoForm
                open ={open}
                isFullScreen={props.isFullScreen}
                from={"main"}
                close={handleClose}
                currentComponentFun={currentComponentFun}
              />
            ) : (
              <OnSubmitComponent
                data={data[currentComponent]}
                close={handleClose}
                from={"outer"}
                reset={reset}
                currentComponent={currentComponent}
                currentComponentFun={currentComponentFun}
              />
            )
          }
        />
      </Box>
    </HeroSliderWrapper>
  );
}
