import styled from "styled-components";

export const HeroBannerWrapper = styled.div`
  .head-logo {
    width: 100px;
    position: absolute;
    top: 9px;
    z-index: 4;
    left: 80px;
    top: 36px;
  }
  .upbanner-btn:hover {
    border-radius: 50px;
    color: #000 !important;
    background:#fff !important;
  }
  .pause-upbanner-btn:hover {
    background: transparent !important;
    text-decoration: underline;
  }
  .banner {
    position: relative;
    z-index:9
  }
  .banner .videoContainer:after {
    background: none;
  }
  .banner .video-playe-ui {
    height: 100vh !important;
  }
  .hero-video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .videoContainer .music-for {
    width: 135px;
  }
  .overlaybutton {
    display: flex;
    margin-top: 18px;
  }
  .overlaybutton button {
    text-transform: capitalize;
  }
  .outerText p { 
    margin-top: 8px;
  }

  .videoContainer {
    clip-path: circle(100%);
    transition: clip-path 0.1s ;
  }

  .animate {
    clip-path: circle(380px at 300px 280px);
    transition: clip-path 2s cubic-bezier(1, 0.81, 0.11, 1);
  }

  @media screen and (min-width: 1601px) {
    .videoContainer .outerText p {
      line-height: 120px !important;
      font-size:150px !important
    }
    .font-Zuume-72 {
      line-height: 120px !important;

      font-size: 150px !important;
    }
  }

  @media screen and (max-width: 767px) {


    .animate {
      clip-path: circle(195px at 160px 310px);
      transition: clip-path 0.8s cubic-bezier(1, 0.81, 0.11, 1);
    }
    .home-text p {
      font-size: 72px;
    }

    .banner .head-logo {
      left: 16px;
      top: 24px;
      width: 72px;
    }
    .music-for {
      width: 120px !important;
    }
    .music-span {
      padding-left: 65px !important;
    }
    .maent-span {
      padding-left: 167px !important;
    }
    .home-text {
      width: auto !important;
    }
    .outerText p {
      font-size: 72px !important;
      line-height: 76px;
    }
    .overlaybutton {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
    .overlaybutton button:nth-child(2) {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 400px) {
    .music-span {
      padding-left: 45px !important;
    }
    .maent-span {
      padding-left: 81px !important;
    }
  }
  .banner .video-playe-ui .overlay {
    background: transparent;
  }
  @media screen and (min-width: 992px) and (max-width: 1024px) {
    .banner .outerText {
      line-height: 108px;
    }
    .animate {
      clip-path: circle(460px at 235px 410px);
      transition: clip-path 0.8s cubic-bezier(1, 0.81, 0.11, 1);
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .animate {
      clip-path: circle(460px at 244px 499px);
      transition: clip-path 0.8s cubic-bezier(1, 0.81, 0.11, 1);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .banner .outerText {
      width: 100% !important;
      padding: ;
    }
    .videoContainer .music-for {
      width: 96px;
    }
    .banner .outerText {
      padding: 0 36px !important;
    }
    .head-logo {
      left: 36px;
      top: 36px;
      width: 90px;
    }
    .banner .outerText .maent-span {
      padding-left: 170px !important;
    }
    .banner .outerText p {
      font-size: 100px;
      line-height: 90px;
    }
  }
  @media screen and (max-width: 992px) {
    .banner .outerText {
      line-height: 76px;
    }
    .banner .outerText p {
      font-size: 72px;
      line-height: 66px;
    }
  }
  
  
  @media screen and (min-width: 1025px) and (max-width: 1250px) {
    .banner .outerText p {
      font-size: 70px!important;
      line-height: 54px!important;
  }
  .head-logo {
    width: 80px !Important;
}
.overlaybutton button{
  padding: 12px 16px; 
}
.overlaybutton button span{
  font-size:14px;
}
.overlaybutton button svg{
  font-size:18px;
}
    .music-span {
      padding-left: 111px !important;
    }
  }
  @media screen and (min-width: 1251px) and (max-width: 1300px) {
    .banner .outerText p {
      font-size: 90px!important;
      line-height: 70px!important;
  }
  .banner .animate{
    clip-path:    circle(365px at 268px 209px);
  }
  .head-logo {
    width: 80px !Important;
}
.overlaybutton button{
  padding: 12px 16px; 
}
.overlaybutton button span{
  font-size:14px;
}
.overlaybutton button svg{
  font-size:18px;
}
    .music-span {
      padding-left: 111px !important;
    }
    .animate{
      clip-path: circle(343px at 245px 219px);
    }
  }

  @media screen and (min-width: 1301px) and (max-width: 1400px) {
    .banner .outerText p {
      font-size: 90px !important;
      line-height: 77px !important;
    }
    .music-span {
      padding-left: 111px !important;
    }
    .banner .animate{
      clip-path: circle(366px at 245px 219px);
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1660px) {

  .animate{
      clip-path: circle(387px at 224px 142px);
  }
}
  @media screen and (max-device-width: 920px) and (orientation: landscape) {
    .banner .outerText p {
      font-size: 46px !important;
      line-height: 39px !important;
    }
    .head-logo {
      left: 36px;
      top: 16px;
      width: 70px !important;
    }
    .music-span {
      padding-left: 62px !important;
    }
    .banner .outerText .maent-span {
      padding-left: 108px !important;
    }
    .overlaybutton button svg {
      font-size: 18px;
    }
    .banner .outerText {
      padding: 0 36px !important;
    }
    .overlaybutton button {
      padding: 8px 9px !important;
    }
    .overlaybutton {
      margin-top: 7px;
      justify-content: start;
    }
  }
  
  @media screen and (min-width:1024px) and (max-device-width: 1280px) and (orientation: landscape) {
    .animate {
      clip-path: circle(351px at 279px 271px);
    }
  }
  @media screen and (min-width:1281px) and (max-device-width: 1400px) and (orientation: landscape) {
    .animate {
      clip-path: circle(452px at 279px 377px);
  }
  }
  @media screen and (min-height: 1530px) and (max-height: 1620px) {
    .font-Zuume-72 {
      font-size: 70px !important;
      line-height: 66px !important;
    }
    .videoContainer .outerText p {
      font-size: 90px!important;
      line-height: 75px!important;
  }
  }
  @media screen and (min-height: 601px) and (max-height: 768px) {
    .outerText p {
      font-size: 75px!important;
      line-height: 75px!important;
  }
  }
  @media screen and (max-height: 600px) {
    .outerText p {
      font-size: 65px !important;
      line-height: 55px !important;
    }
    .videoContainer .music-for {
      width: 120px;
    }
  }
  @media screen and (max-width: 1600px) {
    .head-logo {
      width: 105px;
    }
  }
  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    .animate{
      clip-path:circle(195px at 127px 177px)
    }
    .music-for{
      width:80px !important
    }
  }

 
  
`;
