import React from "react";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AudioSwiperWrapper, PlayListWrapper } from "./styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SliderDialog from "../UI-components/dialogSlider";
import Swiperslider from "../UI-components/swiper";
import dictonary from "../../../dictionary";
import { SwiperSlide } from "swiper/react";
import { Box } from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import { isTouchScreen } from "../../utils";

export function PlaylistSlider(props) {
  const [element, setElement] = React.useState(null);
  const dataimages = dictonary.Pages.landing_page.components.makers.images;
  const [orientation, setOrientation] = React.useState("portrait");
  React.useEffect(() => {
    let ele = document.querySelector("#swipemeplaylist");

    if (ele) {
      setElement(ele);
    }
  }, [element]);

  React.useEffect(() => { }, [orientation]);

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  const sliderContent = props.data.map((e, i) => (
    <SwiperSlide>
      <Box
        key={`videoplaylist${i}`}
        className="video-playlist"
        tabIndex={0}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          background: props.index == i ? "#000" : "transparent",
          color: props.index === i ? "#fff" : "#000",
        }}
        onClick={() => props.setIndex(i)}
      >
        <Box style={{ padding: "5px 5px 0px 5px " }}>
          <img            
            loading={"lazy"}
            alt={dataimages[i].alt_text}
            className="m-auto"
            src={dataimages[i].playList_desk}
          />
        </Box>
        <Box className="title-timer-song">
          <Box className="title-artist-pl">
            <p>{dataimages[i].makersName}</p>
            <p>{dataimages[i].discription}</p>
          </Box>
          {props.index === i ? (
            <IconButton>
              <PauseIcon style={{ color: "#fff" }} />
            </IconButton>
          ) : (
            <Box className="song-duration">{dataimages[i].duration}</Box>
          )}
        </Box>
      </Box>
    </SwiperSlide>
  ));

  function detectswipe(el, func) {
    if (isTouchScreen) {
      let swipe_det = new Object();

      swipe_det.sX = 0;
      swipe_det.sY = 0;
      swipe_det.eX = 0;
      swipe_det.eY = 0;
      var min_x = 50; //min x swipe for horizontal swipe
      var max_x = 50; //max x difference for vertical swipe
      var min_y = 50; //min y swipe for vertical swipe
      var max_y = 50; //max y difference for horizontal swipe
      var direc = "";
      let ele = el;
      ele.addEventListener(
        "touchstart",
        function (e) {
          var t = e.touches[0];
          swipe_det.sX = t.screenX;
          swipe_det.sY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();
          var t = e.touches[0];
          swipe_det.eX = t.screenX;
          swipe_det.eY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchend",
        function (e) {
          //horizontal detection
          if (
            (swipe_det.eX - min_x > swipe_det.sX ||
              swipe_det.eX + min_x < swipe_det.sX) &&
            swipe_det.eY < swipe_det.sY + max_y &&
            swipe_det.sY > swipe_det.eY - max_y
          ) {
            if (swipe_det.eX > swipe_det.sX) direc = "r";
            else direc = "l";
          }
          //vertical detection
          if (
            (swipe_det.eY - min_y > swipe_det.sY ||
              swipe_det.eY + min_y < swipe_det.sY) &&
            swipe_det.eX < swipe_det.sX + max_x &&
            swipe_det.sX > swipe_det.eX - max_x
          ) {
            if (swipe_det.eY > swipe_det.sY) direc = "d";
            else direc = "u";
          }

          if (direc != "") {
            if (typeof func == "function") func(el, direc);
          }
          direc = "";
        },
        false
      );
    }
  }

  function myfunction(el, d) {
    if (!element) return;
    if (d == "d") {
      props.close();
    }
  }

  element !== null && detectswipe(element, myfunction);

  const closethedrawer = (e) => {
    e.preventDefault();

    let x = setTimeout(props.close, 100);
  };

  return (
    <AudioSwiperWrapper>
      <Box className="down-arrow-playlist" id={"swipemeplaylist"}>
        {window.innerWidth > 500 && (
          <IconButton
            edge="start"
            color="inherit"
            style={{ padding: 0 }}
            aria-label="close"
            onClick={(e) => closethedrawer(e)}
          >
            {/* <CloseIcon /> */}
            <KeyboardArrowDownIcon
              style={{ fontSize: "28px", padding: "0px" }}
            />
          </IconButton>
        )}
        <Box className="playlist-text-popup">
          <p className="font-poppins-22">Playlist</p>
          <p>({props.data.length})</p>
        </Box>
      </Box>
      <div className="audio-swiper">
        <Swiperslider
          numberofSlides={props.data.length}
          contentSlides={sliderContent}
          from={"makerplaylist"}
        />
      </div>
    </AudioSwiperWrapper>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HeroSlider(props) {
  const [open, setOpen] = React.useState(false);
  const [orientation, setOrientation] = React.useState("portrait");
  React.useEffect(() => { }, [orientation]);
  React.useEffect(() => {
    let ele = document.querySelector("#swipeme");
    if (ele) {
      setElement(ele);
    }
  }, [element]);
  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [element, setElement] = React.useState(null);
  function detectswipe(el, func) {
    if (isTouchScreen) {
      let swipe_det = new Object();
      swipe_det.sX = 0;
      swipe_det.sY = 0;
      swipe_det.eX = 0;
      swipe_det.eY = 0;
      var min_x = 20; //min x swipe for horizontal swipe
      var max_x = 40; //max x difference for vertical swipe
      var min_y = 40; //min y swipe for vertical swipe
      var max_y = 50; //max y difference for horizontal swipe
      var direc = "";
      let ele = el;
      ele.addEventListener(
        "touchstart",
        function (e) {
          var t = e.touches[0];
          swipe_det.sX = t.screenX;
          swipe_det.sY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();
          var t = e.touches[0];
          swipe_det.eX = t.screenX;
          swipe_det.eY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchend",
        function (e) {
          //horizontal detection
          if (
            (swipe_det.eX - min_x > swipe_det.sX ||
              swipe_det.eX + min_x < swipe_det.sX) &&
            swipe_det.eY < swipe_det.sY + max_y &&
            swipe_det.sY > swipe_det.eY - max_y
          ) {
            if (swipe_det.eX > swipe_det.sX) direc = "r";
            else direc = "l";
          }
          //vertical detection
          if (
            (swipe_det.eY - min_y > swipe_det.sY ||
              swipe_det.eY + min_y < swipe_det.sY) &&
            swipe_det.eX < swipe_det.sX + max_x &&
            swipe_det.sX > swipe_det.eX - max_x
          ) {
            if (swipe_det.eY > swipe_det.sY) direc = "d";
            else direc = "u";
          }

          if (direc != "") {
            if (typeof func == "function") func(el, direc);
          }
          direc = "";
        },
        false
      );
    }
  }

  function myfunction(el, d) {
    if (!element) return;
    if (d == "u") {
      handleClickOpen();
    }
  }

  element != null && detectswipe(element, myfunction);
  return (
    <PlayListWrapper onClick={handleClickOpen}>
      <Box id="swipeme" variant="outlined" className="playlist-img">
        {window.innerWidth < 500 && (
          <Box
            className="mobile-swiper-design"
            id="swipeme"
            onMouseDown={handleClickOpen}
          ></Box>
        )}
        {window.innerWidth > 500 && orientation == "portrait" && (
          <Box className="arrow-icon">
            <IconButton
              style={{ width: "100%", padding: 0, fontSize: "28px" }}
              edge="start"
              color="inherit"
              onMouseDown={handleClickOpen}
              aria-label="Playlist Close"
            >
              {/* <CloseIcon /> */}

              <KeyboardArrowUpIcon style={{ padding: 0, fontSize: "28px" }} />
            </IconButton>
          </Box>
        )}
        <Box className="hero-slider-content" id="swipeme">
          <Box className="playlist-popup">
            <p className="font-poppins-22">Playlist</p>
            <p className="playlist-lenght-web">({props.data.length})</p>
          </Box>
        </Box>
      </Box>
      <Box>
        <SliderDialog
          open={open}
          className="HEllo"
          fullScreen={false}
          close={handleClose}
          sliderHeight={"inherit"}
          style={{
            backgroundColor: "#f5f5f5",
            top: "inherit",
            height:
              window.innerWidth < 980 && window.innerHeight < 560
                ? "70vh"
                : "",
            borderRadius: "18px 18px 0px 0px",
          }}
          contentComponent={<PlaylistSlider {...props} close={handleClose} />}
        />
      </Box>
    </PlayListWrapper>
  );
}
