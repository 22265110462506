import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ManifestWrapper } from "./style";

import dictionary from "../../../dictionary";
import "animate.css";
export default function Manifest(props) {
  const [disabledParallex, setdisabledParallex] = useState(false);
  const [orientation, setOrientation] = useState("portrait");
  useEffect(()=>{},[orientation])

  let device = "desktop_images";
  let imgData =
    dictionary && dictionary.Pages.landing_page.components.menifest[device];

  if (window.innerWidth > 550) {
    const something = () => {
      if (window.pageYOffset > 115) {
        setdisabledParallex(true);

        // downscroll code
      } else {
        setdisabledParallex(false);
      } // else was horizontal scroll
    };

    let portrait = window.matchMedia("(orientation: portrait)");

    portrait.addEventListener("change", function (e) {
      if (e.matches) {
      
        setOrientation("portrait");
      } else {
        
        setOrientation("landscape");
      }
    });

  
    window.addEventListener("scroll", something, false);

    return (
      <ManifestWrapper href={"#manifest"}>
        <Box className="max-width" >
          <Grid container spacing={2} style={{ position: "relative" }}>
            <Grid item xs={12} md={4} sm={4} >
              <div
                className="mani-card"
                style={{ cursor: "pointer" }}
                onClick={(e) => props.skiptoSection(e, "products")}
              >
                <div
                  className="card-img mani-bg product-img1"
                  //  ref={animation1pic1}
                >
                  <div
                    className={"product-img1"}
                    style={{ height: "210px", width: "210px" }}
                    class={
                      disabledParallex
                        ? "animate__animated animate__fadeInDown animate__repeat-1 animate__slow "
                        : ""
                    }
                  >
                    <img   loading={"lazy"} src={imgData[0].img} alt={imgData[0].alt_text} />
                  </div>
                </div>
                <div
                  className="manicard-content"
                  class={
                    disabledParallex
                      ? " manicard-content animate__animated animate__slideInUp animate__repeat-1  animate__slow"
                      : ""
                  }
                  // ref={animationText1}
                >
                  <div className="card-head-text"
                  
                  >
                    <p className="font-Zuume-72 for-text" tabIndex={0}>For The</p>
                    <p
                      className="font-atomic-large ear-text"
                      style={{ textTransform: "uppercase" }}
                    >
                      Ear
                    </p>
                  </div>
                  <p className="font-poppins-regular for-subtext">
                    {imgData[0].main_text}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} sm={4} style={{ zIndex: 0 }} >
              <div
                onClick={(e) => props.skiptoSection(e, "benifit")}
                className="mani-card product-second-mob"
                style={{ cursor: "pointer" }}
                class={
                  disabledParallex
                    ? "animate__animated animate__slideInUp animate__repeat-1   animate__slow"
                    : ""
                }
              >
                <div
                  className="card-img"

                  //ref={animationpic2}
                >
                  <img
                    className="web-img"
                    loading={"lazy"}
                    src={imgData[1].img}
                    alt={imgData[1].alt_text}
                  />
                  {/* <img className="mob-img" src="./images/Manifest_For_The_Stage_Mob.png"  alt={"image"} /> */}
                </div>
                <div
                  className="manicard-content "
                  // ref={animationtext2}
                >
                  <div className="card-head-text">
                    <p className="font-Zuume-72 for-text " tabIndex={0}>For The</p>
                    <p
                      className="font-atomic-large "
                      style={{ textTransform: "lowercase" }}
                    >
                      Stage
                    </p>
                  </div>
                  <p className="font-poppins-regular for-subtext">
                    {imgData[1].main_text}
                  </p>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={4} sm={4} style={{ zIndex: 0 }} >
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => props.skiptoSection(e, "makers")}
                className="mani-card"
                class={
                  disabledParallex
                    ? "animate__animated animate__slideInUp animate__repeat-1   animate__slower"
                    : ""
                }
              >
                <div
                  className="card-img  product-third-mob"
                  // ref={animationpic3}
                >
                  <img
                    className="web-img"
                    src={imgData[2].img}
                    alt={imgData[2].alt_text}
                    loading={"lazy"}
                  />
                  {/* <img
                    className="mob-img"
                    src={imgData[2].img}
                    alt={imgData[2].alt_text}
                  /> */}
                </div>
                <div
                  className="manicard-content mani-card-third"
                  // ref={animationtext3}
                >
                  <div className="card-head-text">
                    <p className="font-Zuume-72 for-text" tabIndex={0}>For The</p>
                    <p
                      className="font-atomic-large"
                      style={{ textTransform: "uppercase" }}
                    >
                      Studio
                    </p>
                  </div>
                  <p className="font-poppins-regular for-subtext">
                    {imgData[2].main_text}{" "}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </ManifestWrapper>
    );
  }
}
