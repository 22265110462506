import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";

export default function dialogSlider(props) {
  const [open, setOpen] = React.useState(false);
  const [orientation, setOrientation] = React.useState("portrait");
  React.useEffect(() => {}, [orientation]);

  useEffect(() => {
    if (props.open) {
      document.querySelector("html").style.overflow = "hidden";
      // document.querySelector("html").style.padding =  window.innerWidth - document.body.offsetWidth;
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
  };

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  return (
    <Dialog
      fullScreen
      open={props.open}
      style={{
        height: props && props.style.height,
        position: "fixed",
        padding: props && props.style.padding,
        backgroundColor: props && props.style.backgroundColor,
        borderRadius: props && props.style.borderRadius,
        top: props.sliderHeight,

        maxWidth: "",
        margin: "0 auto",
      }}
      className={`slider-popup ${props.className}`}
      onClose={props.close}
    >
      {props && props.contentComponent}
    </Dialog>
  );
}
