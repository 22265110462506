import styled from "styled-components";

export const AudioVideoWrapper = styled.div`
  .videoContainer {
    position: relative;
    width: 100%;
    //padding: 20px; 
    background-attachment: scroll;
    overflow: hidden;
    background:#000
  }
  .video-playe-ui {
    position: relative;
    height:100%;
  }
  .video-playlist-ui  .hero-slider-content{
    // padding:24px 80px
  }
  .video-playe-ui .overlay {
    position: absolute;
    padding-bottom:3px;
    bottom: 0;
    display:flex;
    height:100%;
    z-index:2;
    width:100%;
    background: linear-gradient(0deg, #000000 13.43%, rgba(0, 0, 0, 0) 75.02%);

    flex-direction: column;
    justify-content: flex-end;
  }
  .next-prev-disabled{
    color:#808080;
  }
  .overlay:hover  .videoContainer::after{
    background:transparent !important;
  }
  .video-playlist-extra{
    display: flex;
    flex-direction: column;
    position: absolute;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .video-playlist-ui{
    display: flex;
    flex-direction: column;
    
  }
  .outerText p{
    line-height:110px;
  }
  
  .videop-actionbar{
    width:100%; 
    margin:0 auto;
  }
  #overlay:hover .videop-actionbar{
    display:block;
    transition:display 3s ease-in-out ;
  }
  .videoContainer:after{
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    content: ''; 
  } 
  
  .videoContainer video{
    object-fit:fill;
    display: block;
    height:100vh !important
  }
  .overlay:hover .close-btn-vplayer{ 
  }
  .close-vPlayer-parent{
    top: 30px;
    right: 35px;
    position: absolute;
  }
  .close-btn-vplayer:hover{
    color:#fff;
    background:#000
  }
  .close-btn-vplayer{
    color: #000;
    padding: 0px 22px;
    display: flex;
    border-radius: 50px;
    width: 56px;
    background:#fff;
    height: 36px;
    align-items: center;
    justify-content: center;
   }
  &&{
    overflow:hidden
  }
  . {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.9;
    // background-image: linear-gradient(to top, #000000, #3b3b3b, #777777, #b9b9b9, #ffffff);
    );
  }
  @media screen and (max-width: 767px) {
    .close-vPlayer-parent{
      top: 16px;
      right: 16px;
    }
  }
  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    .close-btn-vplayer{
      min-width:20px
    }
    .close-vPlayer-parent svg{
      font-size:14px
    }
    .close-vPlayer-parent{
      top: 14px;
      right: 18px;
    }
  }
  @media screen and (max-device-width: 700px) and (orientation: landscape) {
    .close-btn-vplayer{
      min-width:20px
    }
    .close-vPlayer-parent svg{
      font-size:14px
    }
  }
 
`;

export const VideoplayerOverlay = styled.div`
button,
button svg,
p,
span {
  color: #fff;
}
.caption-active svg {
  Background:#fff;
  color:#000;
  border-radius:50%;
  padding:3px;

}
.caption-btn svg{ 
  padding:3px;

}
// .caption-btn:hover svg {
//   Background:#fff;
//   color:#000;
//   border-radius:7px;
//   padding:3px;
// }
 
.hero-P-pause-btn {
  padding: 16px 24px 16px 24px;
  border-radius: 30px;
  font-family: 'Poppins-regular';
  background: #fff;
  text-transform: capitalize;
  color: #000;
}
.hero-P-pause-btn:hover{
  background:#fff;
  color:#2C8380;

}
.audio-player-main {
  width:100%;
  margin-bottom:80px;
  z-index:4
}
.audio-player-margin{
  width:100%;
  margin-bottom:0px

}
.cc-song-name{
  display: flex;
    justify-content: space-between;
    align-items: flex-start;
} 
&&{
  padding:0px 24px
}
.play-next-prev{
  margin-bottom: 26px;
  cursor:pointer;
}
.hero-P-pause-btn{
  width: 98px;
  padding: 16px 24px;
  color: #000;
  border-radius: 50px;
  border: none;
}
@media screen and (max-width: 767px) {
  .song-name,.singer-name{
    display: block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .hero-P-pause-btn {
    padding: 16px 24px 16px 24px;
    border-radius: 30px;
    font-family: "Poppins-regular";
    background: #fff;
    text-transform: capitalize;
    color: #000;
  }
  .audio-player-main {
    width: 100%;
    margin-bottom: 80px;
    z-index: 4;
  }
  .audio-player-margin {
    width: 100%;
    margin-bottom: 0px;
  }
  .cc-song-name {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  && {
    padding: 0px 24px;
  }
  .play-next-prev {
    margin-bottom: 26px;
  }
  .hero-P-pause-btn {
    width: 98px;
    padding: 16px 24px;
    color: #000;
    border-radius: 50px;
    border: none;
  }
  @media screen and (max-width: 767px) {
    .song-name,
    .singer-name {
      display: block;
      width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .audio-player-main {
      margin-bottom: 103px;
    }
  }
  @media screen and (min-width: 1920px) {
    .audio-player-margin {
      width: 100%;
      margin-bottom: 0px;
    }
  }
  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    
    .play-next-prev{
      margin-bottom:0px !important
    }
     .play-next-prev{

    }
    .audio-player-main{
      margin-bottom: 95px;
    }
    .hero-P-pause-btn {
      width: 78px !important;
      padding: 10px 2px;
    }
  }

  @media screen and (max-device-width: 700px) and (orientation: landscape) {
    .audio-player-main{
      margin-bottom: 95px;
    }
    .hero-P-pause-btn {
      width: 78px;
      padding: 10px 2px;
    }
  }
`;
export const TextWapper = styled.div`
  && {
    position: absolute;
    text-align: center;
    z-index: 1;
    margin: 0 auto;
    left: 0;
    width: 100%;
    bottom: 185px;
  }
  .caption-text {
    background: #000000a8;
    width: auto;
    margin: 0 auto;
    display: table;
    padding: 8px;
  }
  .caption-text p {
    color: #fff;
    width: 100%;
  }
`;
export const AudioSwiperWrapper = styled.div`
  && {
    overflow: hidden;
    padding: 0px 80px 36px;
  }
  .playlist-swiper-auto {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .audio-swiper {
    background: #f5f5f5;
  }
  .down-arrow-playlist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .title-artist-pl p {
    width: 100px;
    line-height: 20px;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .audio-swiper .swiper-slide {
    width: 194px !important;
    margin-right: 12px !important;
  }
  .audio-swiper .swiper-slide-active {
    // background:red;
  }
  .audio-swiper .swiper-slide.active .title-timer-song {
  }
  .title-artist-pl p:first-child {
    // font-weight:bold
  }
  .song-duration {
    border: 1px solid #000;
    padding: 9px 13px;
    font-size: 14px;
    line-height: 16px;

    border-radius: 50px;
  }
  .audio-swiper .swiper-wrapper img {
    height: 96px;
    width: 100%;
    border-radius: 8px;
  }
  .playlist-text-popup {
    display: flex;
    background: #f5f5f5;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    justify-content: space-between;
    padding-bottom: 24px;
  }

  @media screen and (max-width: 767px) {
    .playlist-text-popup {
      flex-direction: column;
    }
    && {
      padding: 16px;
      border-radius: 16px;
      background: #f5f5f5;
    }
    .title-artist-pl p {
      width: 100px;
      line-height: 16px;
      font-size: 14px;
    }
  }
  .audio-swiper_mob {
    background: #f5f5f5;
    height: 100%;
    width: 100%;
  }
  .video-playlist_mobile {
    padding: 6px;
    align-items: center;
    border-radius: 50px;
    margin: 12px 0px;
    padding-right: 16px;
  }
  .mobile-swiper-design {
    height: 2px;
    display: flex;
    background: #000;
    cursor: pointer;
    width: 40px;
    position: absolute;
    top: 16px;
    border-radius: 5px;
  }

  .video-playlist_mobile img {
    height: 60px;
    width: 60px;
    object-fit:cover;
    border-radius: 50px;
    margin-right: 20px;
  }

  .mobile-playlist {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0px 16px 0px;
    flex-direction: column;
    align-items: center;
  }
  .mobile-playlist-scroll {
    height: 510px;
    overflow: auto;
  }
  ::-webkit-scrollbar-track {
    background: #eaeaea;
  }
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    &&{
      padding:0px 16px 36px
    }
    .swiper-Nav{
      margin-top:0px
    }
    .font-poppins-22 {
      font-size: 18px;
      line-height: 18px;
    }
    .playlist-lenght-web{
      font-size:14px;
    }
  }
  @media screen and (max-device-width: 980px) and (orientation: landscape) {
    .playlist-text-popup{
      padding-bottom: 5px;
      font-size: 14px !important;
      flex-direction:row
    }
     
  .audio-swiper .swiper-wrapper img{
    height: 65px;
  }
  .title-artist-pl p,.song-duration{
    font-size: 12px;
  }
  
  }
`;

export const MobilePlaylistWrappper = styled.div`
  .mob-play-list {
    display: flex;
  }
  .mob-play-list p {
    width: 172px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  && {
  }
  .length {
    padding: 8px 22px;
    border-radius: 60px;
    border: 1px solid #000;
    width: 40px;
    text-align: center;
  }
`;
export const PlayListWrapper = styled.div`
  && {
  }

  .playlist-img {
    background: #f5f5f5;
    color: #000;
    display: flex;
    padding: 0px 80px 12px 80px;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px 16px 0px 0px;
    flex-direction: column;
  }
  .playlist-img .hero-slider-content {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
  }
  .arrow-icon {
  }
  .arrow-icon svg {
    margin-left: 30px;
  }
  .mobile-swiper-design {
    height: 2px;
    display: flex;
    background: #000;
    width: 40px;
    position: absolute;
    top: 16px;
    border-radius: 5px;
  }
  .MuiDialog-root {
    top: inherit !important;
  }
  .playlist-popup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .playlist-swiper-auto {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  && { 
    z-index: 23;
    position: absolute;
    bottom: 0;
    // max-Width: 1920px;
    background: #f5f5f5;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
  }
  .Slider-playlist {
    background-color: #f5f5f5;
    border-radius: 18px 18px 0px 0px;
    padding: 48px 80px 36px 80px;
    height: 400px;
  }

  @media screen and (max-width: 1400px) {
    .hero-slider-content {
      padding: 6px 12px;
      display: flex;
      border-radius: 18px 18px 0px 0px;
    }
  }
  @media screen and (max-width: 767px) {
    .hero-slider-content {
      padding: 8px 16px !important;
      height: 68px !important;
      background: #f5f5f5;
      display: flex;
    }
    .playlist-popup {
      justify-content: center;
    }
  }
  @media screen and (max-device-width: 700px) and (orientation: landscape) {
    .hero-slider-content{
      height:22px!important;
    }
    && { 
      background:#fff;
    }
    .playlist-popup{
      justify-content:space-between
    }
    .hero-slider-content{
      padding:0px 16px!important
    }
  }
  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    .playlist-img{
      padding: 0px 16px 8px 16px;
    }
    .hero-slider-content{
      height:22px!important;
    }
    .playlist-popup{
      justify-content:space-between
    }
    .font-poppins-22{
      font-size: 18px;
      line-height: 18px;
    }
    .playlist-lenght-web{
      font-size:14px;
    }
    .arrow-icon {
      height:20px;
      width:20px;
    }
    
  }
`;

export const SliderWrapperPopup = styled.div`
  && {
    background: #000 !important;
    color: #fff;
    height: 100% !important;
  }
  .slider-header {
    display: flex;
    flex-direction: column;
  }

  .form_check input {
    height: 24px;
    width: 24px;
    border-radius: 2px;
  }
  .form_check {
    display: flex;
    align-items: center;
    margin: 28px 0px;
  }
  .join-text {
    display: flex;
    justify-content: space-between;
  }
  .insider-form {
    display: flex;
  }
  .Swipper-close {
    background: #fff;
    width: 50px;
    height: 10px;
    border-radius: 30px;
  }

  .insider-form-left {
    margin-right: 64px;
  }
  .start-text {
    width: 80%;
    margin: 0 auto;
  }
  .subscribe-btn {
    background: #fff;
    border: none;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px;
    -webkit-text-fill-color: #000;
    color:#000;
    border-radius: 30px;
  }
`;
