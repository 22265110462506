import React from "react";

export default function bulletNav(props) {
  const bulletRenderer = () => {
  
    let bullets =
      props.data &&
      props.data.length > 1 &&
      props.data.map((e, i) => (
        <li key={`${i}bullet`}>
          <span
            tabindex={-i}
            aria-label={"slider link"}
            aria-hidden={"true"}
            class={
              props.index == i
                ? props.color === "#fff"
                  ? "is-active"
                  : "is-active_dark"
                : props.color === "#fff"
                ? "classNotActive"
                : "classNotActive_dark"
            }
          ></span>
        </li>
      ));
    return bullets;
  };

  return (
    <div>
      <div class="sbn sbn05">
        <ul>{bulletRenderer()}</ul>
      </div>
    </div>
  );
}
